export const asyncTaskTypes = Object.freeze({
  BULK_IMPORT_COMMUNITY_MEMBER: "AsyncTasks::BulkCommunityMemberImportTask",
  EXPORT_CSV_COMMUNITY_MEMBER_CHARGE:
    "AsyncTasks::ExportCsv::Paywalls::CommunityMemberCharge",
  EXPORT_CSV_COMMUNITY_MEMBER_SUBSCRIPTION:
    "AsyncTasks::ExportCsv::Paywalls::CommunityMemberSubscription",
  ACCESS_GROUP_MANAGE_MEMBERS: "AsyncTasks::AccessGroups::ManageMembersTask",
});

export const asyncTaskStatus = Object.freeze({
  PENDING: "pending",
  PROCESSING: "processing",
  COMPLETED: "completed",
  PARTIAL_SUCCESS: "partial_success",
  FAILED: "failed",
});

export const asyncTaskChannelStatus = Object.freeze({
  FINISHED: "asyncTaskFinished",
  UPDATED: "asyncTaskUpdated",
});

export const CHANNEL_NAME = "AsyncTaskChannel";

export const isAsyncTaskChannelFinished = status =>
  status === asyncTaskChannelStatus.FINISHED;

export const isAsyncTaskChannelUpdated = status =>
  status === asyncTaskChannelStatus.UPDATED;

export const isAsyncTaskCompleted = status =>
  status === asyncTaskStatus.COMPLETED;

export const isAccessGroupManageMembersTask = taskType =>
  taskType === asyncTaskTypes.ACCESS_GROUP_MANAGE_MEMBERS;
