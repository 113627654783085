import { createContext, useContext, useEffect, useState } from "react";
import type { JSXElementConstructor, ReactNode } from "react";
import { ChatThreads } from "@circle-react/components/Chat/MessagingV2/ChatThreads";
import { RailBar as MessagingRailbar } from "@circle-react/components/Chat/MessagingV2/RailBar";
import { RailBar as SpaceRailbar } from "@circle-react/components/Spaces/ChatSpace/RailBar";
import { useSmOrMdOrLgScreenMediaQuery } from "../useMediaQuery";
import { useBoolean } from "../utils/useBoolean";

const RAILBAR_TYPES = {
  PARTICIPANTS: "PARTICIPANTS",
  CHAT_THREADS: "CHAT_THREADS",
} as const;

export type RailbarType = keyof typeof RAILBAR_TYPES;

export interface RailbarContextValue {
  chatRoom: any;
  closeThreadsRailbar: () => void;
  isCourseSpace: boolean;
  isLiveStream: boolean;
  isDrawer: boolean;
  messageHighlightId: any;
  openThreadsRailbar: (message: any, chatRoom: any) => void;
  parentMessage: any;
  RailbarComponent: JSXElementConstructor<any>;
  setMessageHighlightId: (messageId: any) => void;
  shouldShowRailBar: boolean;
  toggleShowRailBar: () => void;
  type: RailbarType;
  updateParentMessage: (message: any) => void;
  isChatSpace: boolean;
}

const initialState = {
  chatRoom: null,
  closeThreadsRailbar: () => {},
  isCourseSpace: false,
  isLiveStream: false,
  isDrawer: false,
  messageHighlightId: null,
  openThreadsRailbar: () => {},
  parentMessage: null,
  RailbarComponent: () => null,
  setMessageHighlightId: () => {},
  shouldShowRailBar: false,
  toggleShowRailBar: () => {},
  type: RAILBAR_TYPES.PARTICIPANTS,
  updateParentMessage: () => {},
  isChatSpace: false,
};

const RailbarContext = createContext<RailbarContextValue>(initialState);
RailbarContext.displayName = "RailbarContext";

export interface RailbarContextProviderProps {
  isChatSpace?: boolean;
  children: ReactNode;
  isLiveStream?: boolean;
  isCourseSpace?: boolean;
  isDrawer?: boolean;
}

export const RailbarContextProvider = ({
  isChatSpace = false,
  children,
  isLiveStream = false,
  isCourseSpace = false,
  isDrawer = false,
}: RailbarContextProviderProps) => {
  const [type, setType] = useState<RailbarType>(initialState.type);
  const [shouldShowRailBar, toggleShowRailBar, setShowRailBar] =
    useBoolean(false);
  const [parentMessage, setParentMessage] = useState<any>(
    initialState.parentMessage,
  );
  const [chatRoom, setChatRoom] = useState<any>(initialState.chatRoom);
  const [messageHighlightId, setMessageHighlightId] = useState<any>(
    initialState.messageHighlightId,
  );

  const isSmallOrMediumDevice = useSmOrMdOrLgScreenMediaQuery();

  useEffect(() => {
    setShowRailBar(!isSmallOrMediumDevice);
  }, [isSmallOrMediumDevice]);

  const openThreadsRailbar = (message: any, chatRoom: any) => {
    setParentMessage(message);
    setChatRoom(chatRoom);
    setType(RAILBAR_TYPES.CHAT_THREADS);
    setShowRailBar(true);
  };

  const closeThreadsRailbar = () => {
    setParentMessage(null);
    setChatRoom(null);
    setType(RAILBAR_TYPES.PARTICIPANTS);
    isSmallOrMediumDevice && setShowRailBar(false);
  };

  const updateParentMessage = (message: any) => {
    setParentMessage(message);
  };

  const getParticipantsRailbarType = () => {
    if (isChatSpace) return SpaceRailbar;
    return MessagingRailbar;
  };

  const RailbarComponent =
    type === RAILBAR_TYPES.PARTICIPANTS
      ? getParticipantsRailbarType()
      : ChatThreads;

  const value: RailbarContextValue = {
    chatRoom,
    closeThreadsRailbar,
    isCourseSpace,
    isLiveStream,
    isChatSpace,
    isDrawer,
    messageHighlightId,
    openThreadsRailbar,
    parentMessage,
    RailbarComponent,
    setMessageHighlightId,
    shouldShowRailBar,
    toggleShowRailBar,
    type,
    updateParentMessage,
  };

  return (
    <RailbarContext.Provider value={value}>{children}</RailbarContext.Provider>
  );
};

export const useRailbar = () => useContext(RailbarContext);
