import type { IconType } from "@circle-react-shared/Icon";

export const ACTION_TYPE_TO_ICON: Record<string, IconType> = {
  send_email: "16-workflows-v2-send-email",
  send_dm: "16-workflows-v2-send-dm",
  send_to_webhook: "16-workflows-v2-send-to-webhook",
  award_points: "16-workflows-v2-award-points",
  remove_community_member_from_community:
    "16-workflows-v2-remove-community-member-from-community",
  enable_messaging_on_member: "16-workflows-v2-enable-messaging-on-member",
  unsubscribe: "16-workflows-v2-unsubscribe",
  disable_messaging_on_member: "16-workflows-v2-disable-messaging-on-member",
  add_tag_to_community_member: "16-workflows-v2-add-tag-to-community-member",
  send_notification_to_member: "16-workflows-v2-send-notification-to-member",
  remove_tag_from_community_member:
    "16-workflows-v2-remove-tag-from-community-member",
  add_community_member_to_access_group:
    "16-workflows-v2-add-community-member-to-access-group",
  remove_community_member_from_access_group:
    "16-workflows-v2-remove-community-member-from-access-group",
  add_community_member_to_space:
    "16-workflows-v2-add-community-member-to-space",
  remove_community_member_from_space:
    "16-workflows-v2-remove-community-member-from-space",
  add_community_member_to_space_group:
    "16-workflows-v2-add-community-member-to-space-group",
  remove_community_member_from_space_group:
    "16-workflows-v2-remove-community-member-from-space-group",
  cancel_community_member_paywall_subscription:
    "16-workflows-v2-cancel-community-member-paywall-subscription",
  subscribe_community_member_to_paywall_trial:
    "16-workflows-v2-subscribe-community-member-to-paywall-trial",
  rsvp_member_to_event: "16-workflows-v2-rsvp-member-to-event",
  time_delay: "16-workflows-v2-time-delay",
};
