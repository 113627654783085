/* eslint-disable eslint-comments/no-restricted-disable, sonarjs/cognitive-complexity
-- There is not enough complexity to warrant a refactor */
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import type { UsedInOptions } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { usePersistFormFields } from "@circle-react/hooks/utils/usePersistFormFields";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { Toolbar } from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar";
import { Button } from "@circle-react-uikit/Button";
import { useEditorInteractions } from "./useEditorInteractions";

export interface TiptapCommentBoxProps {
  autoFocus?: boolean;
  className?: string;
  communityMemberForReply: any;
  editorRef?: RefObject<Editor>;
  initialSgidToObjectMap?: object;
  isEdit?: boolean;
  parentCommentId?: number;
  placeholder?: string;
  postId: number;
  spaceId: number;
  usedIn: UsedInOptions;
  persistCacheKey?: string;
}

export const TiptapCommentBox = ({
  autoFocus = false,
  className,
  communityMemberForReply,
  editorRef,
  initialSgidToObjectMap = {},
  isEdit = false,
  parentCommentId,
  placeholder = t("comments.form.fields.body_placeholder"),
  postId,
  spaceId,
  usedIn,
  persistCacheKey,
}: TiptapCommentBoxProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const {
    formState: { isDirty },
  } = useFormContext();

  usePersistFormFields({
    cacheKey: persistCacheKey,
    fields: ["tiptap_body"],
    enabled: !isEdit && !!persistCacheKey && isDirty,
  });

  const toolbarPortalId = [
    "post",
    postId,
    isEdit ? "edit" : "create",
    parentCommentId,
    "comment-form-toolbar",
    usedIn,
  ]
    .filter(Boolean)
    .join("-");

  const {
    isEditorExpanded,
    commentBoxRef,
    handleEditorFocus,
    editorKey,
    sgidToObjectMap,
    isSubmitDisabled,
    isSubmitting,
    isUsedInMinimalPostModal,
    isPostCommentFormInMinimalPostModal,
  } = useEditorInteractions({
    editorRef,
    autoFocus,
    usedIn,
    parentCommentId,
    communityMemberForReply,
    currentCommunityMember,
    initialSgidToObjectMap,
    toolbarPortalId,
  });

  return (
    <div
      ref={commentBoxRef}
      key={editorKey}
      className={classNames(
        "border-primary focus-within:border-light active:border-light rounded-lg border transition-all duration-300 ease-in-out",
        {
          "pointer-events-none opacity-50": isSubmitting,
          "px-4 py-3": !isPostCommentFormInMinimalPostModal,
          "max-h-[40px] px-4 py-1.5":
            isPostCommentFormInMinimalPostModal && !isEditorExpanded,
          "max-h-[440px] px-4 py-3":
            isPostCommentFormInMinimalPostModal && isEditorExpanded,
        },
      )}
    >
      <div
        className={classNames("transition-all duration-300 ease-in-out", {
          "max-h-[40px] overflow-hidden":
            isUsedInMinimalPostModal && !isEditorExpanded,
          "max-h-[400px]": isUsedInMinimalPostModal && isEditorExpanded,
        })}
      >
        <FormTipTapBlockEditor
          editorRef={editorRef}
          placeholder={placeholder}
          name="tiptap_body.body"
          toolbars={[{ portalId: toolbarPortalId, component: Toolbar }]}
          className={classNames("max-h-[400px] overflow-y-auto", className)}
          editorClassName="min-h-[30px]"
          spaceId={spaceId}
          sgidToObjectMap={sgidToObjectMap}
          disabledExtensions={["poll", "ai_copilot", "cta"]}
          onFocusChange={(isFocused: boolean) => {
            if (isFocused) {
              handleEditorFocus();
            }
          }}
        />
      </div>
      <div
        className={classNames(
          "flex items-end justify-between transition-all duration-300 ease-in-out",
          {
            "invisible max-h-0 overflow-hidden opacity-0":
              isPostCommentFormInMinimalPostModal && !isEditorExpanded,
            "visible max-h-[32px] opacity-100":
              isPostCommentFormInMinimalPostModal && isEditorExpanded,
            "h-0": isPostCommentFormInMinimalPostModal && !isEditorExpanded,
            "h-[32px]": isPostCommentFormInMinimalPostModal && isEditorExpanded,
          },
        )}
      >
        <div
          id={toolbarPortalId}
          className="transition-opacity duration-300 ease-in-out"
        />
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitDisabled}
          className="!px-4 transition-opacity duration-300 ease-in-out"
        >
          {isEdit
            ? isSubmitting
              ? t("comments.create.actions.saving")
              : t("comments.create.actions.save")
            : isSubmitting
              ? t("comments.create.actions.submitting")
              : t("comments.create.actions.submit")}
        </Button>
      </div>
    </div>
  );
};
