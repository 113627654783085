import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { queryClient } from "@circle-react/providers";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useAccessGroupsStatusInfiniteQuery } from "../../AccessGroupsTabs/hooks/useAccessGroupsStatusInfiniteQuery";

interface ApiAccessGroupType {
  accessGroupId: number;
}

export const useArchiveAccessGroup = ({
  hideModal,
}: {
  hideModal: () => void;
}) => {
  const { accessGroupsInfiniteQuery } = useAccessGroupsStatusInfiniteQuery();

  const toastr = useToast();
  const { destroyAccessGroup } = accessGroupsApi();
  const history = useHistory();

  return useMutation<ApiAccessGroupType, ApiError, ApiAccessGroupType>({
    mutationFn: ({ accessGroupId }: ApiAccessGroupType) =>
      destroyAccessGroup(accessGroupId),
    onSuccess: () => {
      // this will invalidate the all counter tabs as it uses the root of the query key
      void queryClient.invalidateQueries(accessGroupsInfiniteQuery.queryKey[0]);
      toastr.success(t("settings.access_groups.admin.messages.archived"));
      history.push(settingsNavPath.members.access_groups);
      hideModal();
    },
    onError: (error: any) => toastr.error(error.message),
  });
};
