import { useCallback, useEffect, useState } from "react";
import { communityInboxApi } from "@circle-react/api/communityInboxApi";
import { useCommunityInboxSync } from "@circle-react/components/Modals/MessagingModal/hooks/useCommunityInboxSync";
import { BotChatRoom } from "@circle-react/components/SettingsApp/CommunityInbox/BotChatRoom";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import { Loader } from "./Loader";
import { ResetButton } from "./ResetButton";
import { usePreviewValues } from "./usePreviewValues";

export const ChatPreview = () => {
  const { messages } = useCommunityInboxStore();
  const [isLoading, setIsLoading] = useState(false);
  const [botChatRoomId, setBotChatRoomId] = useState<number>();
  useCommunityInboxSync({ currentChatId: botChatRoomId, isPreview: true });
  const { setCurrentBotProfile, setPreviewChat, currentBotProfile } =
    useCommunityInboxStore();
  const previewValues = usePreviewValues();

  const setupPreview = useCallback(() => {
    if (previewValues) {
      setIsLoading(true);
      void communityInboxApi
        .previewChat({ community_bot_profile: previewValues })
        .then((response: Chat) => {
          setPreviewChat(response);
          setBotChatRoomId(response.id);
          setCurrentBotProfile(response.community_bot_profile);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [previewValues, setCurrentBotProfile, setPreviewChat]);

  useEffect(() => {
    setupPreview();
  }, [setupPreview]);

  const shouldDisplayResetButton = messages?.length > 0;

  if (!botChatRoomId) {
    return null;
  }

  return (
    <div className="flex h-[calc(100vh-67px)] grow flex-col items-center justify-center gap-7 py-10">
      <div className="bg-primary relative h-full min-w-[436px] max-w-[628px] overflow-hidden rounded-3xl px-2 pb-0 pt-2 shadow-xl md:w-1/2">
        {isLoading && <Loader />}
        {!isLoading && (
          <BotChatRoom
            key={`${botChatRoomId}-${currentBotProfile?.updated_at}`}
            previewId={botChatRoomId}
            isDms
          />
        )}
      </div>
      {shouldDisplayResetButton && <ResetButton onClick={setupPreview} />}
    </div>
  );
};
