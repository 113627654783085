import { useLocation } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";

export const useShouldDisplayV3PreviewBar = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { pathname } = useLocation();
  const is2faPage = pathname.startsWith("/two_fa");

  const isV3Enabled = !!currentCommunitySettings?.circle_v3_enabled;
  const isV3InPreview = !!currentCommunitySettings?.circle_v3_preview;

  return !isV3Enabled && isV3InPreview && !is2faPage;
};
