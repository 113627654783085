import { t } from "@/i18n-js/instance";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { useThemeContext } from "@circle-react/providers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { FormContent } from "./FormContent";

export const KnowledgePage = ({ agent }: { agent: AgentProp }) => {
  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  const { isDarkAppearance } = useThemeContext();

  if (!agent) {
    return null;
  }

  return (
    <div>
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex h-full"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-primary border-r-primary flex w-[453px] flex-col border-r">
          <div className="border-primary border-b px-6 py-5">
            <Typography.LabelLg weight="semibold">
              {t("community_bot.bot_builder.knowledge_page.title")}
            </Typography.LabelLg>
          </div>
          <div className="px-6 py-5">
            <div className="mb-4">
              <Typography.LabelSm weight="semibold">
                {t("community_bot.bot_builder.knowledge_page.sources_label")}
              </Typography.LabelSm>
            </div>
            <FormContent profile={agent} />
          </div>
        </div>
        <ChatPreview />
      </div>
    </div>
  );
};
