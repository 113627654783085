import type { ChatSortType } from "@circle-react/types/CommunityBot/ChatSort";
import { DropdownMenu } from "./DropdownMenu";
import { CHAT_SORT_VALUES } from "./constants";

interface HeaderProps {
  activeSort: ChatSortType;
  setActiveSort: (value: ChatSortType) => void;
}
export const Header = ({ activeSort, setActiveSort }: HeaderProps) => (
  <div className="bg-primary border-primary sticky top-0 z-10 flex h-14 items-center gap-2 border-b px-2">
    {/* <DropdownMenu
      className="w-[40%]"
      items={Object.values(CHAT_TYPES)}
      activeItem={CHAT_TYPES.ALL}
      localeScope="community_inbox.filters"
      onItemClick={() => {}}
    /> */}
    <DropdownMenu
      className="grow"
      items={Object.values(CHAT_SORT_VALUES)}
      activeItem={activeSort}
      localeScope="community_inbox.filters"
      onItemClick={value => setActiveSort(value)}
    />
  </div>
);
