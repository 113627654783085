import { t } from "@/i18n-js/instance";
import { AccessRegistryCard } from "../Card";
import type { BadgeProps } from "./Badge";
import { Badge } from "./Badge";
import { i18nRoot } from "./constants";

export interface CardProps {
  className?: string;
  title: string;
  showAs?: BadgeProps["variant"];
  tooltip?: string;
  actions?: React.ReactNode;
  membersCount?: number;
  spacesCount?: number;
  children?: React.ReactNode;
}

export const Card = ({
  className,
  title,
  showAs,
  tooltip,
  actions,
  membersCount = 0,
  spacesCount = 0,
  children,
}: CardProps) => {
  const highlights = {
    members_count: t([i18nRoot, "highlights", "members_count"], {
      count: membersCount,
    }),
    spaces_count: t([i18nRoot, "highlights", "spaces_count"], {
      count: spacesCount,
    }),
  };

  return (
    <AccessRegistryCard
      className={className}
      title={title}
      titleColor={showAs === "removed" ? "text-disabled" : undefined}
      titleDecor={showAs ? <Badge variant={showAs} /> : null}
      tooltip={tooltip}
      actions={actions}
      highlights={highlights}
    >
      {children}
    </AccessRegistryCard>
  );
};
