import { t } from "@/i18n-js/instance";
import { StatusBadge } from "@circle-react-shared/uikit/StatusBadge";
import { i18nRoot } from "./constants";

export interface BadgeProps {
  variant: "connected" | "removed";
}

export const Badge = ({ variant }: BadgeProps) => (
  <StatusBadge variant={variant === "connected" ? "success" : "default"}>
    {t([i18nRoot, "badge", variant])}
  </StatusBadge>
);
