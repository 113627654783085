import { useMemo } from "react";
import I18n from "@/i18n-js/instance";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { distanceBetweenDates } from "@/react/helpers/dateTimeHelpers";
import { dateStringToTimeAgo } from "@/react/helpers/dateTimeHelpers/timeAgo";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { useSmOrMdOrLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";

const useIsSettingsWorkflowsRoute = (path: string) =>
  useMemo(() => {
    // Matches `/settings/workflows/:id` or `/settings/workflows/new`, `/edit`, `/view`, or these followed by anything
    const regex = /^\/settings\/workflows\/(\d+|new|edit|view)(\/.*)?$/;

    // Excludes `/settings/workflows` and `/settings/workflows?`
    const excludeRegex = /^\/settings\/workflows(\?|$)/;

    return regex.test(path) && !excludeRegex.test(path);
  }, [path]);

export const useTrialBanner = () => {
  const { currentCommunity, currentCommunityMember, currentCommunitySettings } =
    usePunditUserContext();
  const { isCommunityOnTrial } = usePlanStatus();
  const isSmOrMdScreen = useSmOrMdOrLgScreenMediaQuery(); //Trial banner is not shown in small or medium screens
  const isSettingsWorkflowsRoute = useIsSettingsWorkflowsRoute(
    window.location.pathname,
  );

  const isInCommunityCreationPage = useMemo(
    () => window.location.pathname.startsWith("/communities/new"),
    [window.location],
  );

  const isInOnboardingQuestionsPage = useMemo(
    () =>
      // Checks for /communities/{community_id}/questions
      /^\/communities\/\d+\/questions\/?$/.test(window.location.pathname),
    [window.location],
  );

  const isInSelectTemplatePage = useMemo(
    () =>
      // Checks for /communities/{community_id}/templates
      /^\/communities\/\d+\/templates\/?$/.test(window.location.pathname),
    [window.location],
  );

  const trialEndDateFormatted = useMemo(() => {
    if (!currentCommunity?.trial_ends_at) return "";
    return formatDateString(currentCommunity.trial_ends_at, {
      day: "2-digit",
      month: "2-digit",
    });
  }, [currentCommunity]);

  const trialEndsInWords = useMemo(
    () =>
      currentCommunity?.trial_ends_at
        ? dateStringToTimeAgo(currentCommunity.trial_ends_at)
        : "",
    [currentCommunity],
  );

  const trialDaysRemainingInWords = useMemo(() => {
    const daysLeft = distanceBetweenDates(
      new Date(),
      new Date(currentCommunity?.trial_ends_at),
    );
    // Including less than 1 to avoid "0 days left"
    return daysLeft <= 1
      ? `1 ${I18n.t("trial_banner.day")}`
      : `${daysLeft} ${I18n.t("trial_banner.days")}`;
  }, [currentCommunity]);

  const isSubscriptionCancelled = useMemo(() => {
    if (isCommunityOnTrial) {
      return false;
    }

    return currentCommunity?.subscription
      ? Boolean(currentCommunity.subscription.cancelled)
      : false;
  }, [currentCommunity, isCommunityOnTrial]);

  const isPromotionBannerEnabled = useMemo(
    () => !!currentCommunity?.promotion_enabled,
    [currentCommunity],
  );

  const shouldShowTrialBanner = useMemo(() => {
    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const shouldHideTrialBanner = currentCommunitySettings?.hide_trial_banner;
    return Boolean(
      isAdmin &&
        (isCommunityOnTrial || isSubscriptionCancelled) &&
        !isInCommunityCreationPage &&
        !isInOnboardingQuestionsPage &&
        !isInSelectTemplatePage &&
        !shouldHideTrialBanner &&
        !isSmOrMdScreen &&
        !isPromotionBannerEnabled &&
        !isSettingsWorkflowsRoute,
    );
  }, [
    currentCommunity,
    currentCommunityMember,
    isCommunityOnTrial,
    isSubscriptionCancelled,
    isSmOrMdScreen,
    isSettingsWorkflowsRoute,
  ]);

  const trialBannerElement = document.getElementById("trial-banner");
  const isTrialBannerExists = Boolean(trialBannerElement);

  return {
    trialEndsInWords,
    trialDaysRemainingInWords,
    trialEndDateFormatted,
    isSubscriptionCancelled,
    isCommunityOnTrial,
    shouldShowTrialBanner,
    isTrialBannerExists,
  };
};
