import React from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Highlights } from "./Highlights";

export interface AccessRegistryCardProps {
  className?: string;
  title: string;
  titleColor?: "text-disabled";
  titleDecor?: React.ReactNode;
  tooltip?: string;
  actions?: React.ReactNode;
  highlights?: Record<string, string>;
  childrenContainerClassName?: string;
  children?: React.ReactNode;
}

export const AccessRegistryCard = ({
  className,
  title,
  titleColor,
  titleDecor,
  tooltip,
  actions,
  highlights,
  childrenContainerClassName,
  children,
}: AccessRegistryCardProps) => (
  <div
    className={classNames(
      "bg-primary border-primary flex flex-col gap-y-2 rounded-lg border p-6",
      className,
    )}
  >
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-x-2">
        <Typography.LabelMd color={titleColor} weight="semibold">
          {title}
        </Typography.LabelMd>
        {titleDecor}
      </div>
      <div className="flex flex-row items-center gap-x-2">
        {tooltip && (
          <TippyV2 content={tooltip}>
            <Icon type="info" size={16} />
          </TippyV2>
        )}
        {actions}
      </div>
    </div>
    {highlights && <Highlights highlights={highlights} />}
    {children && (
      <div
        className={classNames(
          "border-primary mt-4 flex flex-col border-t pt-4",
          childrenContainerClassName,
        )}
      >
        {children}
      </div>
    )}
  </div>
);
