import { absolutePath } from "./absolutePath";

export const communityInbox = {
  index: ({ params = {} } = {}) =>
    absolutePath(`community_inbox/chats`, params),
  show: ({ id }: { id: number }) => absolutePath(`community_inbox/chats/${id}`),
  create: ({ params = {} } = {}) =>
    absolutePath(`community_inbox/chats`, params),
  preview: () => absolutePath(`community_inbox/chats/preview`),
  fetchMessages: ({ id, params = {} }: { id: number; params: any }) =>
    absolutePath(`community_inbox/chats/${id}/messages`, params),
  createMessage: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/messages`),
  botSearch: ({ params = {} } = {}) =>
    absolutePath(`search/community_bot/profiles`, params),
  adminMarkAsRead: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/admin_mark_as_read`),
  memberMarkAsRead: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/member_mark_as_read`),
  pauseAi: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/pause_ai`),
  resumeAi: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/resume_ai`),
  unreadMemberChatsCount: () =>
    absolutePath(`community_inbox/chats/unread_member_chats_count`),
};
