import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface ListAccessGroupSourcesQueryProps {
  recordType: string;
  recordId?: number;
}

export const listAccessGroupSourceQueryKey = "access-group-sources-by-record";
export const useListAccessGroupSourcesQuery = ({
  recordType,
  recordId,
}: ListAccessGroupSourcesQueryProps) => {
  const { data: accessGroupSources, isLoading: isLoadingAccessGroupSources } =
    useQuery<any[], Error, any>(listAccessGroupSourceQueryKey, () =>
      reactQueryGet(
        internalApi.admin.accessGroupSources.index({
          record_type: recordType,
          record_id: recordId,
        }),
      ),
    );

  return {
    accessGroupSources,
    isLoadingAccessGroupSources,
  };
};
