import { useState } from "react";
import classNames from "classnames";
import { noop } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { EMOJIS } from "./constants";
import { useReactions } from "./useReactions";
import { generateHoverDetails } from "./utils";

export interface ReactionType {
  emoji: string;
  count: number;
  community_member_ids: number[];
}

export interface ReactionProps {
  reaction: ReactionType;
  currentCommunityMemberId: number;
  reactionableId: number;
  reactionableType: string;
}

export const Reaction = ({
  reaction,
  currentCommunityMemberId,
  reactionableId,
  reactionableType,
}: ReactionProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { emoji, count, community_member_ids: communityMemberIds } = reaction;
  const renderedEmoji = EMOJIS[emoji];
  const [communityMembers, setCommunityMembers] = useState([]);
  const isReactedByMe = communityMemberIds.includes(currentCommunityMemberId);
  const hoverDetails = generateHoverDetails(
    communityMembers,
    emoji,
    currentCommunityMemberId,
    count,
  );
  const { addReaction, removeReaction, fetchCommunityMembers, isLoading } =
    useReactions({
      currentCommunityMemberId,
      emoji,
      reactionableId,
      reactionableType,
      setCommunityMembers,
      communityMemberIds,
    });

  const handleReaction = () => {
    if (!currentCommunityMemberId || isLoading) return;

    const body = { emoji: emoji, [reactionableType]: reactionableId };

    if (isReactedByMe) {
      removeReaction(body);
    } else {
      addReaction(body);
    }
  };

  if (!renderedEmoji) return null;

  const content = (
    <button
      type="button"
      className={classNames(
        "text-default border-primary relative flex cursor-pointer gap-1 rounded-full border px-1.5 py-0.5 text-xs font-semibold",
        {
          "!border-circle text-brand hover:border-brand bg-circle/5":
            isReactedByMe,
          "hover:border-gray-400": currentCommunityMemberId && !isReactedByMe,
        },
      )}
      onClick={isViewOnlyMasquerading ? noop : handleReaction}
      onMouseEnter={() => fetchCommunityMembers()}
      data-testid="reaction-content"
    >
      <div>{renderedEmoji}</div>
      <div>{count}</div>
    </button>
  );

  if (communityMembers.length > 0) {
    return (
      <TippyV2 placement="top" content={hoverDetails} interactive={false}>
        {content}
      </TippyV2>
    );
  }

  return <div>{content}</div>;
};
