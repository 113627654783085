import { toQueryString } from "./toQueryString";

export * from "./internalApi";
export * from "./toQueryString";
export * from "./publicLiveStreams";
export * from "./getRouteWithPrevLocation";
export * from "./workflowsSettingsPaths";
export * from "./publicNotificationPreferences";
export * from "./settingsNavPath";
export * from "./mediaTranscriptPaths";
export * from "./memberProfileModalPaths";
export * from "./adminMemberEditPaths";
export * from "./settingsPath";
export * from "./notificationsApi";
export * from "./supportWidgetPaths";
export * from "./authPathsWithRedirect";
export * from "./coursePaths";
export * from "./userProfilePaths";
export * from "./liveStreamPaths";
export * from "./eventsPaths";
export * from "./staticPages";
export * from "./posts";
export * from "./userPaths";
export * from "./communityMembersPath";
export * from "./memberSettingsPaths";
export * from "./featuredAreasVisibility";
export * from "./spaceGroupPaths";
export * from "./activeStorageBlobUrl";

type Params = Record<string, any>;

export const signOutPath = () => "/users/sign_out";

export const notificationsPath = () => "/notifications";
export const newNotificationsCountPath = () =>
  "/notifications/new_notifications_count";

export const notificationPath = ({ id }: { id: string }) =>
  `/notifications/${id}`;

export const chatRoomPath = ({ uuid }: { uuid: string }) => `/messages/${uuid}`;
export const chatBotPath = ({ id }: { id: string }) => `/messages/bot/${id}`;
export const chatInboxPath = ({ id }: { id: string }) =>
  `/settings/ai-inbox/conversations/${id}`;
export const chatRoomsPath = () => "/messages";
export const newChatRoomPath = () => "/messages/new";
export const chatThreadsPath = () => "/threads";
export const bookmarksPath = () => "/bookmarks";
export const chatUsersPath = () => "/communities/chat_users";

export const communitySwitcherPath = () => "/community_switchers";
export const communitySwitcherReorderPath = () =>
  "/community_switchers/reorder";
export const createNewCommunityPath = () => "/communities/new";
export const directUploadPath = () => " /rails/active_storage/direct_uploads";
export const embedsPath = () => "/embeds";

export const actionTextAttachmentsPath = (sgid: string) =>
  `/action_texts/attachments/${sgid}`;

export const updateActionTextAttachmentPropertiesPath = (sgid: string) =>
  `/action_texts/attachments/${sgid}/update_properties`;

export const chatRoomInviteLink = (chat_room_uuid: string) =>
  `${window.location.protocol}//${window.location.host}/messages/${chat_room_uuid}/invite_member`;

export const chatSpacePath = ({ spaceSlug }: { spaceSlug: string }) =>
  `/c/${spaceSlug}`;

export const viewSpacePath = ({
  spaceSlug,
  params = {},
}: {
  spaceSlug: string;
  params?: Params;
}) => `/c/${spaceSlug}?${toQueryString(params)}`;

export const joinSpacePath = ({ params = {} }: { params?: Params } = {}) =>
  `/space_members/new?${toQueryString(params)}`;

export const editSpacePath = ({
  spaceSlug,
  params = {},
}: {
  spaceSlug: string;
  params?: Params;
}) => `/c/${spaceSlug}/settings?${toQueryString(params)}`;

export const spaceInvitePath = (spaceSlug: string) => `/c/${spaceSlug}/invite`;

export const spaceMemberPath = (spaceMemberId: string) =>
  `/space_members/${spaceMemberId}`;

export const homePagePath = () => "/home";
export const homePagePostsPath = () => "/home/home_page/posts";
export const homePageUpcomingEventsPath = () => "/home/home_page/events";
export const homePageSpacesPath = () => "/home/home_page/spaces";
export const homePageBannerPath = () => "/home/home_page/banners";
export const homePageMemberActivityPath = () =>
  "/home/home_page/member_activities";

export const removeOpengraphImagePath = (meta_tag_id: string) =>
  `/opengraph_images/${meta_tag_id}`;
export const resetCommunityWelcomeBannerPreferencePath = () =>
  "/settings/landing_page/welcome_banner_preference";

export const newSessionPath = ({ params }: { params?: Params } = {}) =>
  `/users/sign_in?${toQueryString(params)}`;
export const commentsPath = ({ params }: { params?: Params } = {}) =>
  `/comments?${toQueryString(params)}`;

export const spaceUpdatePath = (id: string, params: Params = {}) =>
  `/internal_api/spaces/${id}?${toQueryString(params)}`;

export const spacePath = (id: string) => `/internal_api/spaces/${id}`;

export const twoFaPath = () => "/two_fa";

export const mentions = {
  index: ({ params }: { params?: Params } = {}) =>
    `/users/mentions.json?${toQueryString(params)}`,
};

export const blobPath = ({
  filename,
  signed_id,
}: {
  filename?: string;
  signed_id: string;
}) =>
  // filename does not matter
  `/rails/active_storage/blobs/redirect/${signed_id}/${filename || "download"}`;

export const communityMembersPath = (uuid: string) =>
  `/internal_api/search/chat_room_community_members/${uuid}/community_members`;

export const getCommunityBetaFeaturesPath = () =>
  "/internal_api/community_settings";

export const userLikes = {
  create: ({ params }: { params?: Params } = {}) =>
    `/user_likes?${toQueryString(params)}`,
  destroy: ({ params }: { params?: Params } = {}) =>
    `/user_likes?${toQueryString(params)}`,
};

export const spaces = {
  show: ({ spaceSlug, params = {} }: any) =>
    `/c/${spaceSlug}/?${toQueryString(params)}`,
  update: ({ spaceSlug }: any) => `/c/${spaceSlug}`,
  members: ({ spaceSlug, params = {} }: any) =>
    `/c/${spaceSlug}/members?${toQueryString(params)}`,
  membersCsv: ({ spaceSlug, params = {} }: any) =>
    `/c/${spaceSlug}/members.csv?${toQueryString(params)}`,
  membersPagination: ({ spaceSlug, params = {} }: any) =>
    `/c/${spaceSlug}/members_pagination?${toQueryString(params)}`,
};

export const memberPaths = {
  edit: ({ memberId }: any) => `/members/edit/${memberId}`,
};

export const planSettingsPath = () => "/settings/plans";

export const subscriptionUpgradePath = (planId: string) =>
  `/subscription/upgrade?plan_id=${planId}`;

export const postFollowers = {
  create: ({ postId }: any) => `/posts/${postId}/post_followers`,
  destroy: ({ postId, id }: any) => `/posts/${postId}/post_followers/${id}`,
};

export const communityInboxPath = () => "/settings/ai-inbox";
