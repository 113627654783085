import { isEmpty, orderBy } from "lodash";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AccessGroupCard } from "@circle-react-shared/AccessRegistry/AccessGroupCard";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { AccessGroupDropdown } from "./AccessGroupDropdown";
import { Wrapper } from "./Wrapper";
import { useAccessGroupsActions } from "./hooks/useAccessGroupsActions";

const i18nRoot = "settings.access_groups.admin.entry_points.association";

export const SelectedAccessGroups = () => {
  const { selectedAccessGroups, isAcessGroupSourceRemoved } =
    useAccessGroupsActions();

  if (isEmpty(selectedAccessGroups)) {
    return (
      <Wrapper className="pt-6">
        <EmptyState
          header={t([i18nRoot, "empty_state.title"])}
          description={t([i18nRoot, "empty_state.description"])}
        />
      </Wrapper>
    );
  }

  const selectedAccessGroupsOrdered = orderBy(
    selectedAccessGroups,
    ["status"],
    ["asc"],
  );

  return (
    <div className="flex flex-col">
      {selectedAccessGroupsOrdered.map((accessGroup, index, array) => (
        <AccessGroupCard
          className={classNames({
            "rounded-none border-t-0": index > 0 && index < array.length - 1,
            "rounded-b-none": index === 0 && array.length > 1,
            "rounded-t-none border-t-0":
              index === array.length - 1 && array.length > 1,
          })}
          key={accessGroup.id}
          title={accessGroup.name}
          showAs={
            isAcessGroupSourceRemoved(accessGroup.status)
              ? "removed"
              : "connected"
          }
          tooltip={
            isAcessGroupSourceRemoved(accessGroup.status)
              ? t([i18nRoot, "removed_tooltip"])
              : undefined
          }
          actions={<AccessGroupDropdown accessGroup={accessGroup} />}
          membersCount={parseInt(accessGroup.total_members ?? "0", 10)}
          spacesCount={parseInt(accessGroup.total_spaces ?? "0", 10)}
        />
      ))}
    </div>
  );
};
