import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  memberProfileModalPathsWithState,
  signOutPath,
} from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { Option } from "@circle-react-shared/uikit/Dropdown/Option";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { POPPER_OPTIONS } from "./constants";
import { useUserProfileIcons } from "./useUserProfileIcons";

export interface ProfileSectionDropdownProps {
  isPreviewMode?: boolean;
}

export const ProfileSectionDropdown = ({
  isPreviewMode = false,
}: ProfileSectionDropdownProps) => {
  const isElectron = !isEmpty(window?.webview?.electronAPI);
  const location = useLocation();
  const history = useHistory();
  const { isV3Enabled, iconSize } = useIsV3();

  const { currentCommunityMember, isViewOnlyMasquerading } =
    usePunditUserContext();
  const { show: showMemberProfileDrawer } = useProfileDrawer();

  const {
    authenticationIcon,
    billingIcon,
    paywallsAffiliatesIcon,
    viewProfileIcon,
    editProfileIcon,
    notificationIcon,
    signOutIcon,
  } = useUserProfileIcons();

  const {
    can_access_billing: shouldShowMemberBilling,
    can_access_authentication_page: canAccessAuthenticationPage,
    can_show_paywalls_affiliate: canShowPaywallsAffiliate,
  } = currentCommunityMember?.policies || {};

  const handleSignOut = () => {
    try {
      if (isElectron) {
        window.webview.electronAPI.logout();
      }
    } finally {
      window.location.href = signOutPath();
    }
  };

  const handleElectronChangeCommunity = () => {
    if (isElectron) {
      window.webview.electronAPI?.changeCommunity?.();
    }
  };

  const onProfileViewClick = () =>
    history.push(memberProfileModalPathsWithState.profile(location));

  const getMemberAuthenticationRoutes = (): Option[] => {
    if (!canAccessAuthenticationPage) return [];

    return [
      {
        value: t("authentication"),
        label: t("authentication"),
        pathTo: memberProfileModalPathsWithState.authentication(location),
        icon: authenticationIcon,
        iconSize,
        dataTestId: "authentication-link",
      },
    ];
  };

  const getMemberBillingRoutes = (): Option[] => {
    if (!shouldShowMemberBilling) return [];

    return [
      {
        value: t("billing"),
        label: t("billing"),
        pathTo: memberProfileModalPathsWithState.billing(location),
        icon: billingIcon,
        iconSize,
        dataTestId: "member-billing-link",
      },
    ];
  };

  const getMemberAffiliateRoutes = (): Option[] => {
    if (!canShowPaywallsAffiliate) return [];

    return [
      {
        value: t("member_profile_modal.affiliate_dashboard.title"),
        label: t("member_profile_modal.affiliate_dashboard.title"),
        pathTo: memberProfileModalPathsWithState.affiliate(location),
        icon: paywallsAffiliatesIcon,
        iconSize,
        dataTestId: "member-affiliate-link",
      },
    ];
  };

  const getMemberDefaultRoutes = (): Option[] => [
    {
      value: t("view_profile"),
      label: t("view_profile"),
      onClick: () =>
        showMemberProfileDrawer({
          memberPublicId: currentCommunityMember?.public_uid,
        }),
      icon: viewProfileIcon,
      iconSize,
      dataTestId: "profile-link",
    },
    {
      value: t("edit_profile"),
      label: t("edit_profile"),
      onClick: onProfileViewClick,
      icon: editProfileIcon,
      iconSize,
      dataTestId: "edit-profile-link",
    },
    {
      value: t("notification"),
      label: t("notification"),
      pathTo: memberProfileModalPathsWithState.notifications(location),
      icon: notificationIcon,
      iconSize,
      dataTestId: "notification-link",
    },
  ];
  const getMemberSignOutRoutes = (): Option[] => {
    const routes: Option[] = [
      {
        value: "separator",
      },
    ];

    if (isElectron) {
      routes.push({
        value: t("change_community"),
        label: t("change_community"),
        onClick: handleElectronChangeCommunity,
      });
    }

    routes.push({
      value: t("sign_out"),
      label: t("sign_out"),
      onClick: handleSignOut,
      icon: signOutIcon,
      iconSize,
    });

    return routes;
  };

  const USER_PROFILE_DROPDOWN_OPTIONS: Option[] = [
    ...getMemberDefaultRoutes(),
    ...getMemberAuthenticationRoutes(),
    ...getMemberBillingRoutes(),
    ...getMemberAffiliateRoutes(),
    ...getMemberSignOutRoutes(),
  ];

  if (!currentCommunityMember) return null;

  const { avatar_url: src, name } = currentCommunityMember || {};

  return (
    <Dropdown
      direction="top-start"
      className={classNames({
        "pointer-events-none": isPreviewMode || isViewOnlyMasquerading,
      })}
      buttonLabel={t("user_menu_options")}
      button={
        <UserImage name={name} src={src} size="8" isFullRounded={isV3Enabled} />
      }
      buttonClassName={classNames({
        "focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 !rounded-full":
          isV3Enabled,
      })}
      buttonWrapperClassName={classNames("flex items-center", {
        "ml-4": !isV3Enabled,
      })}
      popperOptions={POPPER_OPTIONS}
      transitionWrapperClassName="z-30"
      menuWrapperClassName={classNames({
        "!mt-2 !px-0": isV3Enabled,
      })}
      dataTestId="user-profile"
    >
      <div data-testid="user-profile-menu">
        {!isV3Enabled && (
          <Dropdown.Header>{t("header_v3.account")}</Dropdown.Header>
        )}
        <Dropdown.IconWithBadgeList options={USER_PROFILE_DROPDOWN_OPTIONS} />
      </div>
    </Dropdown>
  );
};
