/* eslint-disable max-lines --
  All children of a <Switch> should be <Route> or <Redirect> elements.
  https://v5.reactrouter.com/web/api/Switch/children-node
*/
import { Route, Switch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { BrandedAppsBuilderUIPage } from "@/react/pages/Settings/BrandedAppsBuilderUI";
import { BuilderUIPage } from "@/react/pages/Settings/BuilderUI";
import { MobileAppPage } from "@/react/pages/Settings/MobileApp";
import { CommunityBotAgents } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { CommunityBotKnowledge } from "@circle-react/components/SettingsApp/CommunityBot/Knowledge";
import { CommunityInbox } from "@circle-react/components/SettingsApp/CommunityInbox";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { DiscoverSettingsPage } from "@circle-react/pages/DiscoverSettingsPage";
import { LegalSettingsPage } from "@circle-react/pages/Legal/LegalSettingsPage";
import { ActivityLogsSettingsPage } from "@circle-react/pages/Members/ActivityLogsSettingsPage";
import { MembersBulkImportTasksPage } from "@circle-react/pages/Members/BulkImportTasks";
import { DefaultMembersSettingsPage } from "@circle-react/pages/Members/Default";
import { MemberInvitationLinksPage } from "@circle-react/pages/Members/InvitationLinks";
import { MemberEditInvitationLinksPage } from "@circle-react/pages/Members/InvitationLinks/Edit";
import { MemberNewInvitationLinksPage } from "@circle-react/pages/Members/InvitationLinks/New";
import { InviteMembersPage } from "@circle-react/pages/Members/Invite";
import { ManageMembersPage } from "@circle-react/pages/Members/Manage";
import { OnboardingMembersPage } from "@circle-react/pages/Members/Onboarding";
import { MembersProfileFieldsPage } from "@circle-react/pages/Members/ProfileFields";
import { AccessGroupsPage } from "@circle-react/pages/Settings/AccessGroups";
import { AffiliatesComissionsPage } from "@circle-react/pages/Settings/AffiliatesComissions";
import { AffiliatesDirectoryPage } from "@circle-react/pages/Settings/AffiliatesDirectory";
import { AffiliatesSettingsPage } from "@circle-react/pages/Settings/AffiliatesSettings";
import { AnalyticsSettingsPage } from "@circle-react/pages/Settings/Analytics";
import { ApiSettingsPage } from "@circle-react/pages/Settings/Api";
import { BillingSettingsPage } from "@circle-react/pages/Settings/Billing";
import { BrandedAppsPage } from "@circle-react/pages/Settings/BrandedApps";
import { CodeSnippetsPage } from "@circle-react/pages/Settings/CodeSnippets";
import { CommunityAiPage } from "@circle-react/pages/Settings/CommunityAi";
import { CouponsSettingsPage } from "@circle-react/pages/Settings/Coupons";
import { CustomDomainPage } from "@circle-react/pages/Settings/CustomDomain";
import { DefaultSettingsPage } from "@circle-react/pages/Settings/Default";
import { DevelopersOverviewPage } from "@circle-react/pages/Settings/Developers";
import { EmailsSettingsPage } from "@circle-react/pages/Settings/Emails";
import { EmbedSettingsPage } from "@circle-react/pages/Settings/Embed";
import { GamificationSettingsPage } from "@circle-react/pages/Settings/Gamification";
import { GeneralSettingsPage } from "@circle-react/pages/Settings/General";
import { HomeSettingsPage } from "@circle-react/pages/Settings/Home";
import { LiveStreamsSettingsPage } from "@circle-react/pages/Settings/LiveStreams";
import { MemberBillingSettingsPage } from "@circle-react/pages/Settings/MemberBilling";
import { MemberTagsPage } from "@circle-react/pages/Settings/MemberTags";
import { MessagingSettingsPage } from "@circle-react/pages/Settings/Messaging";
import { ModerationSettingsPage } from "@circle-react/pages/Settings/Moderation";
import { ModerationEditSettingsPage } from "@circle-react/pages/Settings/Moderation/Edit";
import { Navigation } from "@circle-react/pages/Settings/Navigation";
import { OnboardingSettingsPage } from "@circle-react/pages/Settings/Onboarding";
import { PaywallBulkLogsPage } from "@circle-react/pages/Settings/PaywallBulkLogs";
import { PaywallChargesPage } from "@circle-react/pages/Settings/PaywallCharges";
import { PaywallGroupsSettingsPage } from "@circle-react/pages/Settings/PaywallGroups";
import { PaywallPaymentSettingsPage } from "@circle-react/pages/Settings/PaywallPaymentSettings";
import { PaywallSubscriptionsPage } from "@circle-react/pages/Settings/PaywallSubscriptions";
import { PaywallTaxSettingsPage } from "@circle-react/pages/Settings/PaywallTax";
import { PaywallsSettingsPage } from "@circle-react/pages/Settings/Paywalls";
import { PlanSettingsPage } from "@circle-react/pages/Settings/Plan";
import { PostsSettingsPage } from "@circle-react/pages/Settings/Posts";
import { PostsBulkActionsPage } from "@circle-react/pages/Settings/PostsBulkActions";
import { SegmentsPage } from "@circle-react/pages/Settings/Segments";
import { SingleSignOnPage } from "@circle-react/pages/Settings/SingleSignOn";
import { SpacesSettingsPage } from "@circle-react/pages/Settings/Spaces";
import { ThemeSettingsPage } from "@circle-react/pages/Settings/Theme";
import { TopicsSettingsPage } from "@circle-react/pages/Settings/Topics";
import { WeeklyDigestPage } from "@circle-react/pages/Settings/WeeklyDigest";
import { WorkflowsSettingsPage } from "@circle-react/pages/Settings/Workflows";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

export interface SettingsRoutesProps {
  isPaywallTaxAvailable: boolean;
  isAffiliatesEnabled: boolean;
  isWorkflowsEnabled: boolean;
  isPaywallPaymentSettingsAvailable: boolean;
  isV3Enabled: boolean;
  isCommunityBotEnabled: boolean;
}

export const SettingsRoutes = ({
  isPaywallTaxAvailable = false,
  isAffiliatesEnabled = false,
  isWorkflowsEnabled = false,
  isPaywallPaymentSettingsAvailable = false,
  isV3Enabled = false,
  isCommunityBotEnabled = false,
}: SettingsRoutesProps) => (
  <Switch>
    <ProtectedRoute
      component={BrandedAppsBuilderUIPage}
      path="/settings/branded_apps_builder_ui"
      exact
    />
    <ProtectedRoute
      component={BuilderUIPage}
      path="/settings/builder_ui"
      exact
    />
    <ProtectedRoute component={GeneralSettingsPage} path="/settings" exact />
    <ProtectedRoute
      component={CustomDomainPage}
      path="/settings/custom_domain"
      exact
    />
    <ProtectedRoute component={HomeSettingsPage} path="/settings/home" exact />
    <ProtectedRoute
      component={OnboardingSettingsPage}
      path="/settings/onboarding/:tab?"
      exact
    />
    <ProtectedRoute
      component={CommunityAiPage}
      path="/settings/community_ai"
      exact
    />
    <ProtectedRoute
      component={SpacesSettingsPage}
      path="/settings/spaces"
      exact
    />
    <ProtectedRoute
      component={TopicsSettingsPage}
      path="/settings/topics"
      exact
    />
    <ProtectedRoute
      component={PostsBulkActionsPage}
      path="/settings/posts/bulk_actions"
      exact
    />
    <ProtectedRoute
      component={CodeSnippetsPage}
      path="/settings/code_snippets"
      exact
    />
    <ProtectedRoute
      component={WeeklyDigestPage}
      path="/settings/weekly_digest"
      exact
    />
    <ProtectedRoute
      component={MobileAppPage}
      path="/settings/mobile_app"
      exact
    />
    <ProtectedRoute
      component={MessagingSettingsPage}
      path="/settings/messaging"
      exact
    />
    {isV3Enabled && (
      <ProtectedRoute
        component={Navigation}
        path="/settings/navigation"
        exact
      />
    )}
    <ProtectedRoute component={SingleSignOnPage} path="/settings/sso" exact />
    <ProtectedRoute
      component={EmbedSettingsPage}
      path="/settings/embed"
      exact
    />
    <ProtectedRoute
      component={PostsSettingsPage}
      path="/settings/posts"
      exact
    />
    <Route component={PlanSettingsPage} path="/settings/plans" exact />
    <Route component={BillingSettingsPage} path="/settings/billing" />
    <Route component={BrandedAppsPage} path="/settings/branded_apps" />
    <ProtectedRoute component={ApiSettingsPage} path="/settings/api" />
    <ProtectedRoute
      component={ModerationSettingsPage}
      path="/settings/moderation"
      exact
    />
    <ProtectedRoute
      component={ModerationEditSettingsPage}
      path="/settings/moderation/edit"
      exact
    />
    <ProtectedRoute
      component={PaywallGroupsSettingsPage}
      path="/settings/paywall_groups"
      exact
    />
    {isPaywallPaymentSettingsAvailable && (
      <ProtectedRoute
        component={PaywallPaymentSettingsPage}
        path={settingsNavPath.paywalls.payment_settings}
        exact
      />
    )}
    {isPaywallTaxAvailable && (
      <ProtectedRoute
        component={PaywallTaxSettingsPage}
        path="/settings/paywall_tax_settings"
        exact
      />
    )}
    <ProtectedRoute
      component={PaywallsSettingsPage}
      path="/settings/paywalls"
      exact={false}
    />
    {isAffiliatesEnabled && (
      <ProtectedRoute
        component={AffiliatesDirectoryPage}
        path="/settings/affiliates_directory"
        exact
      />
    )}
    {isAffiliatesEnabled && (
      <ProtectedRoute
        component={AffiliatesComissionsPage}
        path="/settings/affiliates_commissions"
        exact
      />
    )}
    {isAffiliatesEnabled && (
      <ProtectedRoute
        component={AffiliatesSettingsPage}
        path="/settings/affiliates_settings"
        exact
      />
    )}
    <ProtectedRoute
      component={MemberBillingSettingsPage}
      path="/settings/member_billing"
    />
    <ProtectedRoute
      component={PaywallSubscriptionsPage}
      path="/settings/paywall_subscriptions"
      exact={false}
    />
    <ProtectedRoute
      component={DevelopersOverviewPage}
      path="/settings/developers"
      exact
    />
    <ProtectedRoute
      component={PaywallChargesPage}
      path="/settings/paywall_charges"
      exact={false}
    />
    <ProtectedRoute
      component={PaywallBulkLogsPage}
      path="/settings/paywall_bulk_logs"
      exact
    />
    <ProtectedRoute
      component={MemberTagsPage}
      path="/settings/member_tags"
      exact={false}
    />
    <ProtectedRoute
      component={CouponsSettingsPage}
      path="/settings/coupons"
      exact
    />
    <ProtectedRoute
      component={ThemeSettingsPage}
      path="/settings/theme"
      exact
    />
    {isCommunityBotEnabled && (
      <ProtectedRoute
        component={CommunityInbox}
        path={["/settings/ai-inbox/conversations/:id", "/settings/ai-inbox"]}
        exact
      />
    )}
    {isCommunityBotEnabled && (
      <ProtectedRoute
        component={CommunityBotKnowledge}
        path="/settings/ai-agents/knowledge"
        exact
      />
    )}
    {isCommunityBotEnabled && (
      <ProtectedRoute
        component={CommunityBotAgents}
        path="/settings/ai-agents/agents"
        exact
      />
    )}
    <Route
      component={LiveStreamsSettingsPage}
      path="/settings/live_streams"
      exact={false}
    />
    <ProtectedRoute
      component={ManageMembersPage}
      path="/audience/manage"
      exact
    />
    <ProtectedRoute
      component={AccessGroupsPage}
      path={settingsNavPath.members.access_groups}
    />
    <ProtectedRoute component={InviteMembersPage} path="/members/invite" />
    <ProtectedRoute
      path="/members/edit/:id"
      exact={false}
      render={() => (
        <DefaultMembersSettingsPage
          pageTitle={t("settings.edit_member.title")}
        />
      )}
    />
    <ProtectedRoute
      component={MemberInvitationLinksPage}
      path="/members/invitation_links"
    />
    <ProtectedRoute
      component={MemberEditInvitationLinksPage}
      path="/members/invitation_links/:id/edit"
      exact
    />
    <ProtectedRoute
      component={MemberNewInvitationLinksPage}
      path="/members/invitation_links/new"
      exact
    />
    <ProtectedRoute
      path="/members/invitation_links/:action"
      exact
      render={() => (
        <DefaultMembersSettingsPage
          pageTitle={t("settings.invitation_links.title")}
        />
      )}
    />
    <ProtectedRoute
      path="/members/invitation_links/:id/:action"
      exact
      render={() => (
        <DefaultMembersSettingsPage
          pageTitle={t("settings.invitation_links.title")}
        />
      )}
    />
    <ProtectedRoute
      component={OnboardingMembersPage}
      path="/members/onboarding/:tab?"
      exact
    />
    <ProtectedRoute
      path="/members/bulk_import"
      exact
      render={() => (
        <DefaultMembersSettingsPage
          pageTitle={t("settings.import_members.title")}
        />
      )}
    />
    <ProtectedRoute
      component={MembersBulkImportTasksPage}
      path="/settings/members/bulk_import_tasks"
      exact
    />
    <ProtectedRoute
      component={MembersProfileFieldsPage}
      path="/members/profile_fields"
    />
    <ProtectedRoute
      component={ActivityLogsSettingsPage}
      path="/settings/members/activity_logs"
      exact
    />
    <ProtectedRoute
      component={LegalSettingsPage}
      path="/settings/legal"
      exact
    />
    <ProtectedRoute
      component={DiscoverSettingsPage}
      path="/settings/discover"
      exact
    />
    <ProtectedRoute
      component={AnalyticsSettingsPage}
      path="/settings/analytics/:action?"
    />
    <ProtectedRoute
      component={GamificationSettingsPage}
      path="/settings/gamification"
    />
    {isWorkflowsEnabled && (
      <ProtectedRoute
        component={WorkflowsSettingsPage}
        path="/settings/workflows"
      />
    )}
    <ProtectedRoute
      component={SegmentsPage}
      path={settingsNavPath.members.segments}
    />
    <ProtectedRoute component={EmailsSettingsPage} path="/settings/emails" />
    <ProtectedRoute
      component={DefaultSettingsPage}
      path="/settings/:component/:action/"
      exact
    />
    <ProtectedRoute
      component={DefaultSettingsPage}
      path="/settings/:component"
      exact
    />
  </Switch>
);
