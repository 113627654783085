import { useRef } from "react";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { ModerationInfo } from "@circle-react/components/ModerationInfo";
import { shouldRenderContent } from "@circle-react/components/ModerationInfo/components/shared";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { CommentsList } from "../CommentsList";
import { ActionMenu } from "./ActionMenu";
import { Body } from "./Body";
import { useEditModal } from "./EditModal";
import { EngagementActions } from "./EngagementActions";
import { useLikesModal } from "./LikesModal";
import { MemberAvatar } from "./MemberAvatar";
import { MemberBio } from "./MemberBio";
import { ReportModal } from "./ReportModal";
import { useDeleteHandler } from "./useDeleteHandler";
import { useLikeHandler } from "./useLikeHandler";
import { useReplyHandler } from "./useReplyHandler";
import { useReportHandler } from "./useReportHandler";

export const Comment = ({
  space,
  post,
  comment,
  showReplyForm = false,
  autofocusReplyForm = false,
  onReplyClick,
  onUpdate,
  onDelete,
  onReplyCreate,
  onReplyDelete,
}) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  const {
    id,
    parent_comment_id: parentCommentId,
    community_member: communityMember,
    replies,
    replies_count: repliesCount,
    flagged_for_approval_at: flaggedForApprovalAt,
    policies,
  } = comment;
  const isFlagged = !!flaggedForApprovalAt;
  const isReply = !!parentCommentId;
  const canReport = policies.can_report && !isFlagged;
  const canReview = policies.can_manage && isFlagged;
  const canEdit = policies.can_edit;
  const canDestroy = policies.can_destroy;
  const canBookmark = policies.can_bookmark;

  const replyFormRef = useRef();

  const {
    openReplyForm,
    shouldShowReplyForm,
    communityMemberForReply,
    canReply,
  } = useReplyHandler({ comment, showReplyForm, replyFormRef });

  const {
    toggleLike: handleLikeClick,
    isLiked,
    likesCount,
    canLike,
    canSeeLikes,
    isLoading: isLikesLoading,
  } = useLikeHandler({ comment });

  const likesModal = useLikesModal();

  const openLikesModal = () =>
    likesModal.show({ id, placeholderLikesCount: likesCount });

  const editModal = useEditModal();
  const openEditModal = () => editModal.show({ space, comment, onUpdate });

  const { isReportModalOpen, openReportModal, closeReportModal, submitReport } =
    useReportHandler({ comment });

  const {
    isDeleteConfirmModalOpen,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
    deleteComment,
    isDeletingComment,
  } = useDeleteHandler({
    comment,
    onDelete,
  });

  const handleReplyClickFunc = onReplyClick || openReplyForm;
  const handleReplyClick = () => handleReplyClickFunc({ communityMember });

  return (
    <div
      className={classNames("post--comment text-dark md:px-0", {
        "px-5": !isReply && !isUsedInMinimalTipTapViewModal,
        "pl-5": isReply && !isUsedInMinimalTipTapViewModal,
      })}
      id={`comment_wrapper_${id}`}
    >
      <div
        className={classNames("post__content py-2.5", {
          "md:px-6": !isUsedInMinimalTipTapViewModal,
        })}
      >
        <div className="flex w-full justify-between">
          <div className="flex w-full pt-1">
            <MemberAvatar comment={comment} />
            <div className="flex w-full flex-col">
              <MemberBio post={post} comment={comment} />
              <Body comment={comment} />
            </div>
          </div>
          <ActionMenu
            comment={comment}
            canReport={canReport}
            canReview={canReview}
            canEdit={canEdit}
            canDestroy={canDestroy}
            canBookmark={canBookmark}
            isReply={isReply}
            onReportClick={isViewOnlyMasquerading ? noop : openReportModal}
            onEditClick={isViewOnlyMasquerading ? noop : openEditModal}
            onDeleteClick={
              isViewOnlyMasquerading ? noop : openDeleteConfirmModal
            }
            onUpdate={onUpdate}
          />
        </div>

        {canManageSpace(space) && shouldRenderContent(comment) && (
          <div className="mt-2 pl-[3.25rem]">
            <ModerationInfo.Regular record={comment} contentType="comment" />
          </div>
        )}
        <EngagementActions
          canReply={canReply}
          canLike={canLike}
          canSeeLikes={canSeeLikes}
          isLiked={isLiked}
          likesCount={likesCount}
          isLikeDisabled={isLikesLoading}
          onReplyClick={isViewOnlyMasquerading ? noop : handleReplyClick}
          onLikeClick={isViewOnlyMasquerading ? noop : handleLikeClick}
          onLikesCountClick={isViewOnlyMasquerading ? noop : openLikesModal}
        />
        {canReport && (
          <ReportModal
            show={isReportModalOpen}
            onClose={closeReportModal}
            onReportSubmit={submitReport}
          />
        )}
        {canDestroy && (
          <ConfirmationModal
            isOpen={isDeleteConfirmModalOpen}
            onClose={closeDeleteConfirmModal}
            onConfirm={deleteComment}
            confirmText={isDeletingComment ? t("deleting") : t("delete")}
            confirmVariant="danger"
            title={t("comments.delete.confirm.title")}
            disabled={isDeletingComment}
          >
            {t("comments.delete.confirm.description")}
          </ConfirmationModal>
        )}
      </div>
      {!isReply && (
        <div
          className={classNames("post__replies md:ml-[3.125rem]", {
            "ml-8": !isUsedInMinimalTipTapViewModal,
            "ml-12": isUsedInMinimalTipTapViewModal,
          })}
        >
          <CommentsList
            space={space}
            post={post}
            parentCommentId={id}
            preloaded
            preloadedComments={replies}
            preloadedCommentsTotalCount={repliesCount}
            showReplyForm={shouldShowReplyForm}
            autofocusReplyForm={autofocusReplyForm}
            communityMemberForReply={communityMemberForReply}
            onCommentReplyClick={openReplyForm}
            onCommentCreate={onReplyCreate}
            onCommentDelete={onReplyDelete}
            formRef={replyFormRef}
          />
        </div>
      )}
    </div>
  );
};
