import type { ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface BodyProps {
  hasPaddingTopMobile?: boolean;
  isMinimalLayout?: boolean;
  children?: ReactNode;
  className?: string;
  wrapperClassName?: string;
}

export const Body = ({
  hasPaddingTopMobile = true,
  isMinimalLayout,
  children,
  className,
  wrapperClassName,
}: BodyProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames(
        "main h-full",
        {
          "bg-secondary": !isMinimalLayout,
        },
        className,
      )}
    >
      <div
        className={classNames(
          "main__wrapper h-full sm:mx-6 md:pt-6",
          {
            "pt-0": !hasPaddingTopMobile,
            "px-4 sm:px-0": isMinimalLayout,
            "lg:mx-6": !isV3Enabled,
            "lg:mx-9": isV3Enabled,
          },
          wrapperClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
