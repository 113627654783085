import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { accessGroupEntryPoints } from "@/react/helpers/accessGroups/accessGroupHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { AccessGroups } from "@circle-react-shared/AccessGroups";
import { TabTitle } from "../TabTitle";
import { AccessContent } from "./AccessContent";

export const AccessTab = () => {
  const { control } = useFormContext();

  const id = useWatch({ control, name: "id" });
  const isAccessGroupsEnabledForExisting =
    useWatch({
      control,
      name: "access_groups_enabled",
    }) && id;

  const { currentCommunitySettings } = usePunditUserContext();
  const isAccessGroupsEnabled =
    (currentCommunitySettings?.access_groups_enabled && !id) ||
    isAccessGroupsEnabledForExisting;

  return (
    <div className="px-4">
      <TabTitle title={t("settings.paywalls.modal.tabs.access.title")} />
      {isAccessGroupsEnabled ? (
        <div className="flex h-[50vh] flex-col overflow-auto pb-4">
          <AccessGroups
            description={t(
              "settings.access_groups.admin.entry_points.association.paywall.description",
            )}
            recordType={accessGroupEntryPoints.paywall}
            areFormItemsEmbedded
            spaceAccessNode={
              isAccessGroupsEnabledForExisting ? null : (
                <div className="-mt-2">
                  <AccessContent isAccessGroupsEnabled />
                </div>
              )
            }
            isAccessGroupsTabEnabled={isAccessGroupsEnabled}
            canRemoveAllAccessGroups={false}
          />
        </div>
      ) : (
        <AccessContent />
      )}
    </div>
  );
};
