import { usePunditUserContext } from "@circle-react/contexts";
import { OpenQPModal } from "../OpenQPModal";
import { QuickPostBox } from "./QuickPostBox";

export const QuickPostBoxModal = () => {
  const { currentCommunityMember = {} } = usePunditUserContext();
  const { name, avatar_url } = currentCommunityMember;

  return (
    <OpenQPModal
      render={({ onClick }) => (
        <QuickPostBox
          userName={name}
          userAvatarUrl={avatar_url}
          onClick={onClick}
        />
      )}
    />
  );
};
