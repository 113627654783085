import { useMemo } from "react";
import { omit } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";

const PREVIEW_DEBOUNCE_MILLISECONDS = 1000;

export const usePreviewValues = () => {
  const { control } = useFormContext();
  const values = useWatch({ control });
  const previewValues = useMemo(
    () => ({
      ...omit(values, [
        "id",
        "enabled",
        "members_filters",
        "send_welcome_message",
      ]),
      avatar: values.avatar || values.avatar_signed_id,
    }),
    [values],
  );
  return useDebouncedValue(previewValues, PREVIEW_DEBOUNCE_MILLISECONDS);
};
