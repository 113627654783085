import type { ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { popOverInOut } from "@circle-react-shared/uikit/animations";
import { Typography } from "@circle-react-uikit/Typography";
import { ButtonItem } from "./ButtonItem";
import { NavigationMenuProvider } from "./Context";
import { LinkItem } from "./LinkItem";

interface NavigationMenuProps {
  label?: string;
  active?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  children?: ReactNode;
}

export const NavigationMenuComponent = ({
  label,
  active,
  buttonRef,
  children,
}: NavigationMenuProps) => (
  <Popover className="relative z-[11]">
    {({ open, close }) => (
      <NavigationMenuProvider isOpen={open} close={close}>
        <Popover.Button
          ref={buttonRef}
          className={classNames(
            "border-primary hover:bg-overlay-dark focus-visible:outline-secondary text-darkest max-w-[200px] shrink-0 truncate rounded-full border px-4 py-2 text-sm transition-colors duration-100 focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
            {
              "bg-overlay-dark": open,
              "bg-primary": !open,
              "border-secondary": active,
            },
          )}
        >
          <Typography.LabelSm weight="medium" color="text-primary">
            <span className="flex gap-x-2">
              {label || "..."}
              <Icon type="16-chevron-down" size={14} />
            </span>
          </Typography.LabelSm>
        </Popover.Button>
        <Transition {...popOverInOut}>
          <Popover.Panel className="border-primary bg-primary absolute left-0 top-1.5 flex w-max flex-col rounded-2xl border p-4 shadow-lg">
            {children}
          </Popover.Panel>
        </Transition>
      </NavigationMenuProvider>
    )}
  </Popover>
);

export const NavigationMenu = Object.assign(NavigationMenuComponent, {
  Button: ButtonItem,
  Link: LinkItem,
});
