import { some } from "lodash";
import { MAX_CHAR_LENGTH, MAX_MEDIA_NODE_COUNT } from "../constants";
import type { Node, ParsedContent, TruncationState } from "../types";
import { isMediaNode, isNodeEmpty, removeEmptyNodes } from "./nodeUtils";

/**
 * Utility for truncating a Node-based content structure according to a maximum
 * character limit and a maximum media node limit. Text nodes are truncated if
 * they exceed the character limit, and media nodes are capped at a specified
 * number (i.e., MAX_MEDIA_NODE_COUNT). This was done to handle FE truncation
 * of content with media attachments during Circle 3.0, to have a consistent
 * truncation behaviour for feeds. This also gives us better performance.
 *
 * Slack refs:
 * 1. https://circleso.slack.com/archives/C06SXTVQG4X/p1724269133752449
 * 2. https://circleso.slack.com/archives/C0344JZBXRB/p1725652324287429
 *
 * The idea is to have a maximum of 250 textual characters, and a maximum of 3 media
 * nodes without any textual characters. If the content exceeds the character limit,
 * the text will be truncated and the first media node will be rendered below the text.
 *
 * Key points:
 * 1. A post can have a maximum of 3 media nodes, if there are no textual
 * characters.
 * 2. For a post with only textual characters, the truncation will be done at
 * 250 characters.
 * 3. For a post with both textual and media nodes, the truncation will be done
 * at 250 characters, and the first media node will be rendered below the text.
 * 4. We continue to render custom HTML snippets/embeds/polls/etc the same way
 * they were rendered before Circle 3.0.
 * 5. The available media nodes are defined in `MEDIA_NODE_TYPES` in
 * `constants.ts`.
 *
 * Variables:
 * 1. `MAX_CHAR_LENGTH`: Defines the maximum allowable character length for text (250).
 * 2. `MAX_MEDIA_NODE_COUNT`: Defines the maximum number of media nodes allowed (3).
 *
 * Functions:
 * 1. `traverseNodesAndTruncateContent`: Recursively walks through the content,
 *    handling text and media nodes accordingly.
 * 2. `handleMediaNodeContent`: Handles media nodes, incrementing the `mediaCount`
 *    up to the allowed limit.
 * 5. `handleTextNodeContent`: Handles text node truncation when nearing the
 *    character limit.
 * 6. `handleNestedContent`: Processes nested content arrays within nodes.
 * 7. `checkAndSetLimit`: Determines if either the character limit or the media
 *    limit has been reached, setting `hasTruncatedContent`.
 */

export const truncateContentWithMediaHandling = (
  content?: Node[] | null,
): ParsedContent => {
  if (!content) {
    return {
      truncatedContent: [],
      mediaContent: null,
      hasTruncatedContent: false,
      hasMediaAttachmentsBeforeTruncation: false,
    };
  }

  const state: TruncationState = {
    charCount: 0,
    mediaCount: 0,
    truncatedContent: [],
    mediaContent: null,
    hasTruncatedContent: false,
    textLengthCache: new Map(),
    hasMediaAttachmentsBeforeTruncation: false,
  };

  traverseNodesAndTruncateContent(content, state);

  return {
    truncatedContent: removeEmptyNodes(state.truncatedContent),
    mediaContent: state.mediaContent,
    hasTruncatedContent: state.hasTruncatedContent,
    hasMediaAttachmentsBeforeTruncation:
      state.hasMediaAttachmentsBeforeTruncation,
  };
};

const setMediaContentIfNoneExists = (
  node: Node,
  state: TruncationState,
): boolean => {
  if (!state.mediaContent) {
    state.mediaContent = node;
    return true;
  }
  return false;
};

const handleNode = (node: Node, state: TruncationState): void => {
  if (isMediaNode(node)) {
    handleMediaNodeContent(node, state);
  } else if (node.content && Array.isArray(node.content)) {
    handleNestedContent(node, state);
  } else {
    handleTextNodeContent(node, state);
  }
};

const handleNestedContent = (node: Node, state: TruncationState): void => {
  const nodeCopy = { ...node, content: [] };
  state.truncatedContent.push(nodeCopy);

  for (const childNode of node.content!) {
    if (state.hasTruncatedContent) break;

    const childLength = calculateNodeTextLength(childNode, state);
    const remainingChars = MAX_CHAR_LENGTH - state.charCount;

    if (childLength <= remainingChars) {
      handleNode(childNode, { ...state, truncatedContent: nodeCopy.content });
      state.charCount += childLength;
    } else {
      truncateTextNodeContent(childNode, remainingChars, {
        ...state,
        truncatedContent: nodeCopy.content,
      });
      state.hasTruncatedContent = true;
    }

    checkAndSetLimit(state);
  }
};

const checkAndSetLimit = (state: TruncationState): void => {
  const isCharacterOrMediaLimitReached =
    state.charCount > MAX_CHAR_LENGTH ||
    state.mediaCount > MAX_MEDIA_NODE_COUNT;

  if (isCharacterOrMediaLimitReached) {
    state.hasTruncatedContent = true;
  }
};

const traverseNodesAndTruncateContent = (
  nodes: Node[],
  state: TruncationState,
): boolean =>
  some(nodes, node => {
    if (isNodeEmpty(node)) return false;

    const shouldHandleMedia = state.hasTruncatedContent && isMediaNode(node);
    const shouldTraverseContent =
      state.hasTruncatedContent && Array.isArray(node.content);

    if (shouldHandleMedia && setMediaContentIfNoneExists(node, state)) {
      return true;
    }

    if (!state.hasTruncatedContent) {
      handleNode(node, state);
      checkAndSetLimit(state);
    }

    return (
      shouldTraverseContent &&
      traverseNodesAndTruncateContent(node.content ?? [], state)
    );
  });

const handleMediaNodeContent = (node: Node, state: TruncationState): void => {
  state.hasMediaAttachmentsBeforeTruncation = true;

  if (state.mediaCount < MAX_MEDIA_NODE_COUNT) {
    state.mediaCount += 1;
    state.truncatedContent.push({ ...node });
  } else {
    setMediaContentIfNoneExists(node, state);
    state.hasTruncatedContent = true;
  }
};

const handleTextNodeContent = (node: Node, state: TruncationState): void => {
  const nodeLength = calculateNodeTextLength(node, state);
  const remainingChars = MAX_CHAR_LENGTH - state.charCount;

  if (nodeLength <= remainingChars) {
    state.charCount += nodeLength;
    state.truncatedContent.push({ ...node });
  } else {
    truncateTextNodeContent(node, remainingChars, state);
    state.hasTruncatedContent = true;
  }
};

const truncateTextNodeContent = (
  node: Node,
  remainingChars: number,
  state: TruncationState,
): void => {
  const nodeCopy = { ...node };
  if (typeof node.text === "string") {
    nodeCopy.text = node.text.slice(0, remainingChars);
    if (nodeCopy.text) {
      if (remainingChars < node.text.length) {
        nodeCopy.text += "...";
      }
      state.truncatedContent.push(nodeCopy);
      state.charCount += nodeCopy.text.length;
    }
  } else if (node.content && Array.isArray(node.content)) {
    nodeCopy.content = [];
    state.truncatedContent.push(nodeCopy);
    traverseNodesAndTruncateContent(node.content, {
      ...state,
      truncatedContent: nodeCopy.content,
    });
  }
};

const calculateNodeTextLength = (
  node: Node,
  state: TruncationState,
): number => {
  if (state.textLengthCache.has(node)) {
    return state.textLengthCache.get(node) ?? 0;
  }

  let length = 0;
  if (typeof node.text === "string") {
    length = node.text.length;
  } else if (node.content && Array.isArray(node.content)) {
    length = node.content.reduce(
      (sum, childNode) => sum + calculateNodeTextLength(childNode, state),
      0,
    );
  }

  state.textLengthCache.set(node, length);
  return length;
};
