import { useMemo } from "react";
import type { ReactNode } from "react";
import { compact } from "lodash";
import { t } from "@/i18n-js/instance";
import { DEFAULT_PAGE_SIZE } from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { MemberNotInGroupIdCheckBoxCell } from "./Cells/MemberNotInGroupIdCheckBoxCell";
import { NameCell } from "./Cells/NameCell";
import { formatDate } from "./Cells/functions";
import { getRole } from "./Cells/functions";
import { MembersFilters } from "./MembersFilters";

interface TableColumn {
  accessorKey: string;
  header: string;
  id?: string;
  accessorFn?: (row: any) => any;
  cell?: (info: any) => JSX.Element | string;
  canSort?: boolean;
  thClassName?: string;
  cellClassName?: string;
}

const getTableColumns = (): TableColumn[] =>
  compact([
    {
      accessorKey: "memberIdCheckbox",
      header: "",
      accessorFn: row => row,
      cell: info => (
        <MemberNotInGroupIdCheckBoxCell
          communityMemberId={info.getValue().id}
        />
      ),
      thClassName: "!px-3",
      cellClassName: "!align-middle",
    },
    {
      accessorKey: "name",
      header: t("settings.manage_members.name"),
      accessorFn: row => row,
      cell: info => <NameCell member={info.getValue()} />,
      thClassName: "!px-3",
    },
    {
      accessorKey: "roles",
      header: t("settings.manage_members.role"),
      cell: info => getRole(info.getValue()),
      canSort: false,
      thClassName: "!px-3",
      cellClassName: "!align-middle",
    },
    {
      accessorKey: "profile_confirmed_at",
      header: t("settings.manage_members.joined"),
      cell: info => formatDate(info.getValue()),
      thClassName: "!px-3",
      cellClassName: "!align-middle",
    },
  ]);

interface AccessGroupMembersTablePropsInterface {
  onSort: (sort: any) => void;
  infiniteQuery: any;
  setPage: (page: any) => void;
  order: Record<string, "asc" | "desc">;
  initialPage: number;
  shouldShowBulkActions?: boolean;
  children: ReactNode;
}

export const MembersNotInGroupTable = ({
  onSort,
  setPage,
  initialPage,
  infiniteQuery,
  order,
  children,
}: AccessGroupMembersTablePropsInterface) => {
  const reactTableInitialSort = useMemo(() => {
    if (!order) return null;
    return Object.entries(order).map(([key, value]) => ({
      id: key,
      desc: value === "desc",
    }));
  }, [order]);

  const columns: TableColumn[] = useMemo(() => getTableColumns(), []);

  return (
    <>
      <MembersFilters />
      <InfiniteQueryDataTable
        columns={columns}
        infiniteQuery={infiniteQuery}
        initialSort={reactTableInitialSort}
        onChangeSorting={onSort}
        onChangePage={(newPage: any) => {
          setPage(newPage);
        }}
        initialPage={initialPage}
        pageSize={DEFAULT_PAGE_SIZE}
      >
        <div className="flex h-full flex-col">
          {children}
          <div className="max-w-full overflow-x-auto px-6 lg:px-9">
            <DataTable.Table className="w-full min-w-[800px]">
              <colgroup>
                <col className="w-4" />
                <col />
                <col className="w-3/12" />
                <col className="min-w-[4rem]" />
              </colgroup>
              <DataTable.SortableHead />
              <DataTable.Loader />
              <DataTable.NoRecords
                text={t("settings.manage_members.no_members_found")}
              />
              <DataTable.Body />
            </DataTable.Table>
            <DataTable.Pagination align="left" direction="reverse" />
          </div>
        </div>
      </InfiniteQueryDataTable>
    </>
  );
};
