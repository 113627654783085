import { memo, useEffect, useRef, useState } from "react";
import type { PlyrEvent } from "plyr";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { mediaTranscriptPaths } from "@circle-react/helpers/urlHelpers";
import { useMediaTranscoding } from "@circle-react/hooks/useMediaTranscoding";
import type { MediaTranscoding } from "@circle-react/types/MediaTranscoding";
import { MediaPlayer } from "@circle-react-uikit/MediaPlayer";
import { useMediaSettings } from "@circle-react-uikit/MediaSettings/useMediaSettings";
import { MediaSettings } from "../MediaSettings";
import { TranscodingOverlay } from "./TranscodingOverlay";
import { TranscriptNotification } from "./TranscriptNotification";
import { TranscriptPopup } from "./TranscriptPopup";
import { useMediaPlaybackStore } from "./store/useMediaPlaybackStore";
import { useMediaTranscript } from "./useMediaTranscript";
import { isMediaContentTypeNativelySupported } from "./utils";

interface VideoVariants {
  original: string;
  hls: string | null;
}

export interface TranscriptsPlayerProps {
  src: string;
  signedId?: string | undefined;
  poster?: string;
  type: string;
  isDownloadable?: boolean;
  isTranscodingEnabled?: boolean;
  downloadUrl?: string;
  mediaTranscoding?: MediaTranscoding;
  mediaTranscriptId?: any;
  variants?: VideoVariants;
  preload?: "none" | "metadata" | "auto";
  menuPosition?: "top" | "bottom";
  onProgress?: (event: PlyrEvent) => void;
}

interface PlayerRef {
  plyr: Plyr;
}

export const TranscriptsPlayerComponent = memo(
  ({
    src,
    poster,
    type,
    mediaTranscoding,
    mediaTranscriptId,
    isDownloadable = false,
    isTranscodingEnabled = false,
    downloadUrl,
    variants,
    preload = "none",
    menuPosition = "top",
    signedId,
    onProgress,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity -- Already existed before maximum complexity value was reduced
  TranscriptsPlayerProps) => {
    const { currentCommunityMember, currentCommunitySettings } =
      usePunditUserContext();
    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const playerRef = useRef<PlayerRef | null>(null);
    const fullscreenPlayerRef = useRef<PlayerRef | null>(null);
    const [createdMediaTranscriptId, setCreatedMediaTranscriptId] =
      useState(null);
    const hlsSrc = isTranscodingEnabled ? variants?.hls : null;
    const [playbackSrc, setPlaybackSrc] = useState(hlsSrc ?? src);
    const isMediaHls = playbackSrc.endsWith(".m3u8");
    const isMediaNativelySupported = isMediaContentTypeNativelySupported(type);
    const isMediaSupported = isMediaHls || isMediaNativelySupported;

    const storedMediaProgress = useMediaPlaybackStore(state =>
      state.getProgress(signedId || ""),
    );

    const deleteMediaProgress = useMediaPlaybackStore(
      state => state.deleteProgress,
    );

    const saveMediaProgress = useMediaPlaybackStore(
      state => state.saveProgress,
    );

    const onEnded = () => {
      if (signedId) {
        deleteMediaProgress(signedId);
      }
    };

    const [currentTime, setCurrentTime] = useState(storedMediaProgress);

    const currentMediaTranscriptId =
      mediaTranscriptId || createdMediaTranscriptId;

    const hasTranscripts =
      !!currentCommunitySettings?.community_ai_settings_enabled;

    const { mediaSettings } = useMediaSettings(signedId);
    const onSeek = (time: any) => {
      const playerElement = playerRef.current;
      if (!playerElement) {
        return;
      }
      playerElement.plyr.currentTime = time;
    };

    const onFullscreenSeek = (time: any) => {
      const playerElement = fullscreenPlayerRef.current;
      if (!playerElement) {
        return;
      }
      playerElement.plyr.currentTime = time;
    };

    const onTranscriptFullscreenToggle = () => {
      const playerElement = playerRef.current;
      if (!playerElement) {
        return;
      }
      playerElement.plyr.pause();
    };

    useEffect(() => {
      if (currentTime && signedId) {
        saveMediaProgress(signedId, currentTime);
      }
    }, [currentTime, signedId, saveMediaProgress]);

    const { status: transcodingStatus } = useMediaTranscoding({
      enabled: isTranscodingEnabled && !isMediaSupported,
      signedId,
      mediaTranscoding,
      onSuccess: hlsUrl => hlsUrl && setPlaybackSrc(hlsUrl),
    });

    const {
      parsedWebvtt: { cues },
      mediaTranscript,
      canEdit,
      updateAsync,
      isUpdating,
      isLoading,
      refetch,
    } = useMediaTranscript(currentMediaTranscriptId);
    const onMediaTranscriptUpdated = (id: any) => {
      if (currentMediaTranscriptId !== id) {
        setCreatedMediaTranscriptId(id);
      } else {
        void refetch();
      }
    };

    const videoPoster = mediaSettings ? mediaSettings.poster_url : poster;

    useEffect(() => {
      if (videoPoster) {
        const playerElement = playerRef.current;
        if (playerElement) {
          playerElement.plyr.poster = videoPoster;
        }
      }
    }, [videoPoster]);

    const shouldDisplayTranscodingOverlay =
      isTranscodingEnabled &&
      !isMediaSupported &&
      transcodingStatus !== "succeeded";

    const shouldDisplayTranscript =
      hasTranscripts && mediaTranscript?.enabled && mediaTranscript?.is_ready;

    const shouldDisplayNotification =
      hasTranscripts && !mediaTranscriptId && currentCommunityMember?.is_admin;

    const id =
      typeof mediaTranscriptId === "number" ? String(mediaTranscriptId) : "";

    const captionsUrl = shouldDisplayTranscript
      ? mediaTranscriptPaths.showVtt({ id })
      : undefined;

    const renderFullscreenPlayer = () => (
      <MediaPlayer
        captionsUrl={captionsUrl}
        contentType={type}
        downloadUrl={downloadUrl ?? variants?.original}
        isDownloadable={isDownloadable || isAdmin}
        onTimeUpdate={setCurrentTime}
        playerRef={fullscreenPlayerRef}
        poster={videoPoster}
        src={playbackSrc}
        startTime={currentTime}
        preload={preload}
        onEnded={onEnded}
        onProgress={onProgress}
      />
    );

    return (
      <div className="group relative">
        {shouldDisplayTranscodingOverlay && (
          <TranscodingOverlay status={transcodingStatus} />
        )}
        <MediaPlayer
          captionsUrl={captionsUrl}
          contentType={type}
          downloadUrl={downloadUrl ?? variants?.original}
          isDownloadable={isDownloadable || isAdmin}
          onTimeUpdate={setCurrentTime}
          playerRef={playerRef}
          menuPosition={menuPosition}
          poster={videoPoster}
          src={playbackSrc}
          startTime={currentTime}
          preload={preload}
          onEnded={onEnded}
          onProgress={onProgress}
        />
        <MediaSettings
          signedId={signedId}
          mediaTranscript={mediaTranscript}
          canEditTranscript={canEdit}
          updateAsyncTranscript={updateAsync}
          isUpdatingTranscript={isUpdating}
          contentType={type}
        />
        {shouldDisplayTranscript && (
          <TranscriptPopup
            mediaTranscriptId={mediaTranscriptId}
            onSeek={onSeek}
            onFullscreenSeek={onFullscreenSeek}
            renderFullscreenPlayer={renderFullscreenPlayer}
            onFullscreenToggle={onTranscriptFullscreenToggle}
            cues={cues}
            currentTime={currentTime}
          />
        )}
        {shouldDisplayNotification && (
          <TranscriptNotification
            url={src}
            onNewMediaTranscript={setCreatedMediaTranscriptId}
            onMediaTranscriptUpdated={onMediaTranscriptUpdated}
            mediaTranscript={mediaTranscript}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  },
);

TranscriptsPlayerComponent.displayName = "TranscriptsPlayerComponent";
