import { t } from "@/i18n-js/instance";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { planSettingsPath } from "@/react/helpers/urlHelpers";

export const TrialOffer = () => {
  const {
    isCommunityOnTrial,
    trialDaysRemainingInWords,
    trialEndDateFormatted,
  } = useTrialBanner();

  return (
    <>
      {isCommunityOnTrial && (
        <div>
          <div>
            {t("trial_banner.trial_ends_in", {
              trialDaysRemainingInWords,
              trialEndDateFormatted,
            })}
            <a
              className="hover:!text-badge text-badge ml-1 cursor-pointer underline"
              href={planSettingsPath()}
            >
              {t("trial_banner.upgrade_plan")}
            </a>{" "}
            {t("trial_banner.before_trial_expires")}
          </div>
        </div>
      )}
    </>
  );
};
