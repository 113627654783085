import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { updateOrRemoveQueryParam } from "@circle-react/helpers/urlHelpers";
import {
  DEFAULT_PAGE_SIZE,
  useCommunityMembersInfiniteQuery,
} from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { AccessGroupMembersBulkActions } from "./AccessGroupMembersBulkActions";
import { AccessGroupMembersEmptyState } from "./AccessGroupMembersEmptyState";
import { AccessGroupMembersTable } from "./AccessGroupMembersTable/AccessGroupMembersTable";
import { useFilterParams } from "./AccessGroupMembersTable/hooks/useFilterParams";
import { useAccessGroupData } from "./DataStoreContext/AccessGroupDataContext";

const pageQueryName = "page";
const i18nBase = "settings.access_groups.admin.modal.members";

export const AccessGroupMembersContent = () => {
  const queryParams = useRouterQueryParams();
  const initialPage = Number(queryParams[pageQueryName] || 1);
  const [page, setPage] = useState(initialPage);
  const history = useHistory();
  const {
    accessGroup,
    updateFiltersToAddMembers,
    updateTotalMembersToProcess,
    selectedMemberIds,
  } = useAccessGroupData();

  const {
    query,
    order,
    onSort,
    filters,
    scope,
    getGroupMembersInitialQueryOptions,
  } = useFilterParams({
    accessGroupId: accessGroup.id,
    shouldLoadGroupMembers: true,
  });

  let queryOptions = {
    order: order,
    initialPage: initialPage,
    query: query,
    filters: filters,
    scope: scope,
  };

  const isAddingMembers = location.pathname.includes("members/add");

  if (isAddingMembers) {
    // we have a restriction when rendering 2 tables as they grab the same query string to filter
    // so using default values to list group members when adding new ones, so results in the first table
    // (group members) are not affected when using filters used to add new members
    // TODO: scope query strings based on the table
    queryOptions = getGroupMembersInitialQueryOptions();
  }

  useEffect(() => {
    history.push({
      search: updateOrRemoveQueryParam(pageQueryName, String(page)),
    });
  }, [page, history]);

  const infiniteQuery = useCommunityMembersInfiniteQuery({
    perPage: DEFAULT_PAGE_SIZE,
    options: {
      keepPreviousData: false,
    },
    isAdminScope: true,
    ...queryOptions,
  });

  useEffect(() => {
    updateFiltersToAddMembers(filters);
    updateTotalMembersToProcess(infiniteQuery.totalQueryMembers);
  }, [
    infiniteQuery.totalQueryMembers,
    updateTotalMembersToProcess,
    filters,
    updateFiltersToAddMembers,
  ]);

  if (parseInt(accessGroup?.total_members ?? "0") === 0) {
    return <AccessGroupMembersEmptyState />;
  }

  return (
    <AccessGroupMembersTable
      initialPage={queryOptions.initialPage}
      order={queryOptions.order}
      onSort={onSort}
      setPage={setPage}
      infiniteQuery={infiniteQuery}
    >
      <DataTable.Headline>
        <DataTable.Headline.Title>
          <div className="flex items-center gap-5">
            {selectedMemberIds.length == 0
              ? t([i18nBase, "count"], {
                  count: infiniteQuery.totalQueryMembers,
                })
              : t([i18nBase, "count_selected"], {
                  count: infiniteQuery.totalQueryMembers,
                  total_selected: selectedMemberIds.length,
                })}
          </div>
        </DataTable.Headline.Title>
        <AccessGroupMembersBulkActions />
      </DataTable.Headline>
    </AccessGroupMembersTable>
  );
};
