import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPublicStream } from "@circle-react/helpers/liveStreamRoomHelpers";
import { publicLiveStreams } from "@circle-react/helpers/urlHelpers";
import { useRoom } from "./useRoom";

interface UseGuestsOptions {
  refetchInterval?: number | null;
}

export const useGuests = ({
  refetchInterval = null,
}: UseGuestsOptions = {}) => {
  const { currentCommunity = {}, isLoading: isCommunityLoading } =
    usePunditUserContext();
  const { roomSlug } = useParams<{ roomSlug: string }>();
  const room = useRoom();

  const { data: guests, isLoading } = useQuery(
    ["public-live-stream", "guests", roomSlug, currentCommunity?.id],
    () =>
      reactQueryGet(
        publicLiveStreams.rooms.guests.index({
          slug: roomSlug,
          params: {
            community_id: currentCommunity?.id,
          },
        }),
      ),
    {
      enabled:
        Boolean(room) &&
        isPublicStream(room) &&
        !isCommunityLoading &&
        Boolean(roomSlug),
      refetchInterval: refetchInterval || false,
    },
  );
  return {
    guests,
    count: guests?.length || 0,
    isLoading,
  };
};
