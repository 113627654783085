import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { AgentAvatar } from "./AgentAvatar";
import { AgentEnableSwitch } from "./AgentEnableSwitch";
import { AgentsBanner } from "./AgentsBanner";
import { AgentsDropdownActions } from "./AgentsDropdownActions";
import { AnswerTypeIcon } from "./AnswerTypeIcon";
import { AudienceTypeIcon } from "./AudienceIcon";
import { BehaviorTypeIcon } from "./BehaviorTypeIcon";
import { NoAgents } from "./NoAgents";
import { useIndexAgents } from "./agentsQueries";
import { useShowSetting } from "./queries";

export interface AgentProp {
  id: number;
  enabled: boolean;
  name: string;
  avatar_url: string;
  created_at: string;
  updated_at: string;
  answer_type: "ai-generated" | "custom";
  tone: "normal" | "friendly" | "short-and-sweet" | "descriptive";
  purpose: string;
  use_community_knowledge: boolean;
  use_custom_snippets: boolean;
  use_platform_support: boolean;
  posts_count: number;
  snippets_count: number;
  members_filters?: Array<BaseFilter | ProfileFieldBaseFilter>;
  send_welcome_message: boolean;
}

const columns = [
  {
    header: t("settings.community_bot.agents.columns.name"),
    accessorKey: "name",
    cell: ({ row }: any) => (
      <div className="ml-2 flex items-center gap-4">
        {row.original?.avatar_url ? (
          <img
            src={row.original.avatar_url}
            alt={t("community_bot.bot_builder.customize_page.avatar")}
            loading="eager"
            className="h-8 w-8 rounded-full"
            width={32}
            height={32}
          />
        ) : (
          <AgentAvatar />
        )}
        <span className="font-[InterVariable,sans-serif] font-semibold">
          {row.original?.name}
        </span>
        <div className="hidden items-center gap-1 md:flex">
          <TippyV2
            content={t(
              "settings.community_bot.agents.ai_generated_answers_button.title",
            )}
          >
            <AnswerTypeIcon answerType={row.original?.answer_type} />
          </TippyV2>
          <TippyV2 content={row.original?.members_filters_tooltip}>
            <AudienceTypeIcon />
          </TippyV2>
          <TippyV2
            content={t(
              `settings.community_bot.agents.behavior_type_button.options.${row.original?.tone.replaceAll("-", "_")}`,
            )}
          >
            <BehaviorTypeIcon tone={row.original?.tone} />
          </TippyV2>
        </div>
      </div>
    ),
    canSort: false,
  },
  {
    header: t("settings.community_bot.agents.columns.interactions"),
    accessorKey: "interactions",
    cell: ({ row }: any) => row.original?.interactions,
    canSort: false,
  },
  {
    header: t("settings.community_bot.agents.columns.modified_on"),
    accessorKey: "modified_on",
    cell: ({ row }: any) => row.original?.modified_on,
    canSort: false,
  },
  {
    header: t("settings.community_bot.agents.columns.active"),
    accessorKey: "active",
    cell: ({ row }: any) => <AgentEnableSwitch row={row} />,
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: any) => <AgentsDropdownActions agent={row.original} />,
  },
];

export const CommunityBotAgents = () => {
  const [currentPage, setPage] = useState(1);
  const agentsQuery = useIndexAgents({ currentPage });
  const settingQuery = useShowSetting();

  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    agentsQuery.remove();
  };

  if (agentsQuery.isLoading) {
    return <Loader />;
  }

  if (
    !agentsQuery ||
    !agentsQuery.data ||
    !agentsQuery.data.records ||
    agentsQuery.data.records.length === 0
  ) {
    return <NoAgents />;
  }

  const handleEditAgent = (agent: AgentProp) => {
    window.location.href = botBuilderNavPaths.customize({
      botPublicId: agent.id.toString(),
    });
  };

  return (
    <div className="bg-primary flex h-full flex-col">
      <SettingsHeader pageTitle={t("settings.community_bot.agents.title")} />
      {!settingQuery?.data?.hide_agents_banner && (
        <AgentsBanner
          agents={agentsQuery?.data?.records ?? []}
          handleEditAgent={handleEditAgent}
        />
      )}
      <DataTable.TableWrapper>
        <DataTable
          columns={columns}
          onClickRow={({ original, clickEvent }) => {
            if (clickEvent && clickEvent.target) {
              const wrapper = document.getElementById(
                `toggle-wrapper-agent-${original.id}`,
              );

              if (!wrapper?.contains(clickEvent.target)) {
                handleEditAgent(original);
              }
            }
          }}
          data={agentsQuery?.data?.records ?? []}
          isLoading={agentsQuery?.isLoading}
          initialPage={agentsQuery?.data?.page}
          pageSize={agentsQuery?.data?.per_page}
          totalRecordsCount={agentsQuery?.data?.count}
          onChangePage={onChangePage}
        >
          <DataTable.Table>
            <DataTable.Header />
            <DataTable.Loader />
            <DataTable.Body />
          </DataTable.Table>
        </DataTable>
      </DataTable.TableWrapper>
    </div>
  );
};
