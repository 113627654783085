import classNames from "classnames";
import { isThisYear } from "date-fns";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";

interface BodyProps {
  isDirect: boolean;
  chatRoomName: string;
  timestamp: string;
  lastMessage: any;
  isUnread?: boolean;
}
export const Body = ({
  isDirect,
  chatRoomName,
  timestamp,
  lastMessage,
  isUnread,
}: BodyProps) => {
  const getMessageSenderName = () =>
    isDirect ? "" : `${lastMessage.sender?.name}: `;

  const renderMessageBody = () =>
    lastMessage.body?.circle_ios_fallback_text || "";

  const isOldMessage = !isThisYear(new Date(lastMessage.created_at));

  return (
    <div className="w-full">
      <div className="flex items-center space-x-2">
        <span
          className={classNames(
            "shrink-0",
            isOldMessage ? "max-w-[106px]" : "max-w-[130px]",
          )}
        >
          <Typography.BodySm truncate weight="font-semibold">
            {chatRoomName}
          </Typography.BodySm>
        </span>
        <span className="mt-[-1px] min-w-fit shrink-0">
          <Typography.BodySm
            as="time"
            weight="font-medium"
            color="text-timestamp"
          >
            {timestamp}
          </Typography.BodySm>
        </span>
        {isUnread && (
          <span className="flex grow justify-end">
            <Icon type="16-inbox-unread" size={16} />
          </span>
        )}
      </div>
      <div className="mt-0.5">
        <p className="text-default !line-clamp-1 flex items-center overflow-hidden hyphens-auto break-all text-sm">
          {`${getMessageSenderName()}${renderMessageBody()}`}
        </p>
      </div>
    </div>
  );
};
