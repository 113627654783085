import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { isConference } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLiveStreamRoom } from "./useLiveStreamRoom";
import { useParticipants } from "./useParticipants";
import { useRecorder } from "./useRecorder";

const LiveStreamContext = createContext({});
LiveStreamContext.displayName = "LiveStreamContext";

export const LiveStreamContextProvider = ({ children }) => {
  const { roomSlug } = useParams();
  const {
    room,
    isLoading: isLoadingRoom,
    isError: isRoomError,
    apiError,
    joinAsHost,
    joinAsParticipant,
    isJoining,
    currentParticipant,
    updateRoom,
    updateRoomAsync,
    updateRoomSilentlyAsync,
    goLive,
    goLiveAsync,
    leave,
    leaveAsync,
    endStream,
    endStreamDelayed,
    endStreamDelayedAsync,
    onEndStream,
    isLeaving,
    refetchRoom,
    streamEnded,
    viewTypeOverride,
    setViewTypeOverride,
  } = useLiveStreamRoom(roomSlug);

  const { recorder, createRecorder, isCreatingRecorder } = useRecorder(room);

  const { participants, isLoadingParticipants, refetchParticipants, roles } =
    useParticipants(room, currentParticipant, recorder);

  const value = {
    status: {
      isLoadingRoom,
      isLoadingParticipants,
      isRoomError,
      apiError,
      isJoining,
      isCreatingRecorder,
      isLeaving,
      streamEnded,
    },
    data: {
      room,
      recorder,
      participants,
      roles,
      canHost: !!room && room.can_host,
      currentParticipant,
      viewTypeOverride,
      isConferenceRoom: isConference(room),
    },
    actions: {
      joinAsHost,
      joinAsParticipant,
      createRecorder,
      updateRoom,
      updateRoomAsync,
      updateRoomSilentlyAsync,
      goLive,
      goLiveAsync,
      leave,
      leaveAsync,
      endStream,
      endStreamDelayed,
      endStreamDelayedAsync,
      onEndStream,
      refetchRoom,
      refetchParticipants,
      setViewTypeOverride,
    },
  };

  return (
    <LiveStreamContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </LiveStreamContext.Provider>
  );
};

// General context hook with raw value
export const useLiveStreamContext = () => useContext(LiveStreamContext);

export const useLiveStreamContextData = () => useLiveStreamContext().data;

export const useLiveStreamContextActions = () => useLiveStreamContext().actions;

LiveStreamContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
