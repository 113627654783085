import type { UseQueryOptions } from "react-query";
import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { communitySignupUrl } from "@/react/helpers/communityHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useReactQueryLocalStorage } from "@/react/hooks/utils/useReactQueryLocalStorage";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { LOCALSTORAGE_KEYS } from "@circle-react/config/Settings";
import { FIVE_MIN_STALE_TIME } from "@circle-react/providers/QueryClient/reactQueryConfig";
import type { CommunitySettings } from "@circle-react/types/CommunitySettings";

const PUNDIT_USER_QUERY_KEY = "pundit-user";

const usePunditAPI = (
  options?: Omit<
    UseQueryOptions<any, ApiError, any, typeof PUNDIT_USER_QUERY_KEY>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();

  return useQuery(
    PUNDIT_USER_QUERY_KEY,
    () => reactQueryGet(internalApi.punditUser.show()),
    {
      notifyOnChangeProps: "tracked",
      ...options,
      onSuccess: data => {
        // Update stale time based on response
        const isCached = Boolean(
          data?.current_community_settings?.cached_pundit_api_enabled,
        );
        queryClient.setQueryDefaults(PUNDIT_USER_QUERY_KEY, {
          staleTime: isCached ? FIVE_MIN_STALE_TIME : 0,
        });

        // Call original onSuccess if provided
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );
};

export interface UsePunditUserContextReturn {
  refetch: () => Promise<any>;
  isLoading: boolean;
  data: any;
  currentUser: any;
  currentCommunity: any;
  currentCommunitySettings?: CommunitySettings;
  currentCommunityMember: any;
  displayCommunitySwitcher: any;
  isViewOnlyMasquerading: any;
  masqueradingEntity: any;
}

export const usePunditUserContext = (): UsePunditUserContextReturn => {
  const { data, refetch, isLoading } = usePunditAPI();

  const localStorageResponse = useReactQueryLocalStorage({
    key: LOCALSTORAGE_KEYS.PunditUserContext,
    data,
    defaultValue: {},
  });

  const resData = localStorageResponse.data;
  const currentUser = resData?.current_user;
  const currentCommunity = resData?.current_community;
  const currentCommunitySettings = resData?.current_community_settings;
  const currentCommunityMember = resData?.current_community_member;
  const displayCommunitySwitcher = resData?.display_community_switcher;
  const isViewOnlyMasquerading = resData?.view_only_masquerading;
  const masqueradingEntity = resData?.masquerading_entity;

  return {
    refetch,
    isLoading: isLoading ? localStorageResponse.isLoading : false,
    data: resData,
    currentUser,
    currentCommunity,
    currentCommunitySettings,
    currentCommunityMember,
    displayCommunitySwitcher,
    isViewOnlyMasquerading,
    masqueradingEntity,
  };
};

export const useCurrentCommunityMember = () =>
  usePunditUserContext().currentCommunityMember;

export const useCurrentUser = () => usePunditUserContext().currentUser;

export const useIfSignupEnabled = () => {
  const { currentCommunity, currentUser } = usePunditUserContext();

  return (callbackFn: (data: any) => any) => {
    if (currentUser) return null;
    const _communitySignupUrl = communitySignupUrl(currentCommunity);

    if (_communitySignupUrl) {
      return callbackFn({ communitySignupUrl: _communitySignupUrl });
    }
  };
};

/**
 * Returns true if the current user is already a member of the community, or if
 * they can become one if the current community has public signups enabled.
 * Returns false otherwise.
 */
export const useIsOrCanBeCommunityMember = () => {
  const { currentCommunityMember, currentCommunity } = usePunditUserContext();

  return Boolean(
    currentCommunityMember ||
      currentCommunity?.allow_signups_to_public_community,
  );
};
