import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";

export const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_EVENT = "clicked";

interface UseRecipientListQueryArgs {
  id: number;
  event_type: string;
  filters?: { email?: string; name?: string; link?: string };
  perPage?: number;
  initialPage?: number;
  options?: Record<string, any>;
}

export const useRecipientListInfiniteQuery = (
  args: UseRecipientListQueryArgs,
) => {
  const {
    event_type = DEFAULT_EVENT,
    filters = {},
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    id,
    options = {},
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params: Record<string, unknown> = {
      page,
      filters,
      event_type,
      per_page: perPage,
    };
    return broadcastsApi.recipients({ id, params });
  };

  const queryKey = ["recipient-list", id, event_type, filters, perPage];

  const queryOptions = {
    getNextPageParam: (lastPage: any) =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (firstPage: any) =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const isRecipientRecordsProcessing =
    pages[0]?.meta?.is_recipient_records_processing;
  const totalQueryMembers = isRecipientRecordsProcessing
    ? pages[0]?.meta?.recipient_count
    : (pages[0]?.count ?? 0);
  const members = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    isRecipientRecordsProcessing,
    totalQueryMembers,
    members,
  };
};
