import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@/react/contexts";
import { useIsGranularDmSettingsEnabled } from "@/react/hooks/useIsMessagingEnabled";
import { ToggleSwitchFormItem } from "@circle-react-shared/ProfileFields/FormFields/Permissions/ToggleSwitchFormItem";
import type { ItemProps } from "@circle-react-uikit/Form/Item";

interface MemberMessagingToggleProps extends ItemProps {
  name: string;
}

export const MemberMessagingToggle = ({ name }: MemberMessagingToggleProps) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const messagingEnabled = currentCommunity?.messaging_enabled;
  const isAdmin = currentCommunityMember?.is_admin;
  const isGranularDmSettingsEnabled = useIsGranularDmSettingsEnabled();

  //remove_granular_dm_settings_feature_flag: remove isGranularDmSettingsEnabled condition and else part
  let messagingTooltip = messagingEnabled
    ? t(
        "profile_fields.form.preferences.prevent_members_from_messaging_tooltip",
      )
    : t("account.member_edit_profile_form.direct_messaging_turned_off");
  if (isGranularDmSettingsEnabled) {
    messagingTooltip = messagingEnabled
      ? isAdmin
        ? t(
            "profile_fields.form.preferences.prevent_members_from_messaging_tooltip_for_admin",
          )
        : t(
            "profile_fields.form.preferences.prevent_members_from_messaging_tooltip_for_member",
          )
      : t("account.member_edit_profile_form.direct_messaging_turned_off");
  }

  return (
    <ToggleSwitchFormItem
      name={name}
      isDisabled={!messagingEnabled}
      tooltipText={messagingTooltip}
    />
  );
};
