import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { FormTipTapBlockEditor } from "@circle-react/components/shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { useThemeContext } from "@circle-react/providers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Form } from "@circle-react-uikit/Form";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { AvatarUploader } from "./AvatarUploader";

export const CustomizePage = ({ agent }: { agent: AgentProp }) => {
  const { isDarkAppearance } = useThemeContext();

  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  if (!agent) return null;

  return (
    <div className="flex flex-col">
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex h-full items-stretch justify-start"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-primary w-[453px] shrink-0 flex-col">
          <div className="border-primary border-b px-6 py-5">
            <Typography.LabelLg weight="semibold">
              {t("community_bot.bot_builder.customize_page.title")}
            </Typography.LabelLg>
          </div>
          <div className="h-auto px-6">
            <Form.Label
              fieldId="avatar"
              label={t("community_bot.profiles.avatar")}
              hideBorder
              labelClassName="cursor-text"
            />
            <TippyV2
              content={t(
                "community_bot.bot_builder.customize_page.avatar_tooltip_text",
              )}
            >
              <AvatarUploader avatarUrl={agent.avatar_url} />
            </TippyV2>
            <Form.Label
              fieldId="welcome_message"
              label={t("community_bot.profiles.welcome_message_label")}
              hideBorder
              labelClassName="cursor-text"
            />
            <div
              className={classNames(
                "focus-within:border-secondary border-primary h-28 resize-y overflow-y-auto rounded-md border px-4 py-3",
              )}
            >
              <FormTipTapBlockEditor
                mode="all"
                name="welcome_message.body"
                editorClassName="quick-post-modal--v2"
                placeholder={t(
                  "community_bot.profiles.welcome_message_placeholder",
                )}
              />
            </div>
            <Form.Label
              fieldId="bio"
              label={t("community_bot.profiles.bio_label")}
              hideBorder
              labelClassName="cursor-text"
            />
            <div
              className={classNames(
                "focus-within:border-secondary border-primary h-28 resize-y overflow-y-auto rounded-md border px-4 py-3",
              )}
            >
              <FormTipTapBlockEditor
                mode="all"
                name="bio.body"
                editorClassName="quick-post-modal--v2"
                placeholder={t("community_bot.profiles.bio_placeholder")}
              />
            </div>
          </div>
        </div>
        <ChatPreview />
      </div>
    </div>
  );
};
