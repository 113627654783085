import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import type { Source } from "@circle-react/types/CommunityInbox/Message";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Sources } from "./Sources";

interface ContentBlockProps {
  authorName: string;
  timestamp: string;
  body: object;
  sources?: Source[];
}
export const ContentBlock = ({
  authorName,
  timestamp,
  body,
  sources,
}: ContentBlockProps) => {
  const time = formattedMessageSentAt(timestamp).time;

  return (
    <div className="min-w-0 grow">
      <div className="flex items-center gap-1.5">
        <Typography.LabelSm weight="semibold" color="text-darkest" truncate>
          {authorName}
        </Typography.LabelSm>
        <Typography.LabelSm weight="medium" color="text-timestamp">
          {time}
        </Typography.LabelSm>
      </div>
      <div className="text-dark whitespace-pre-line text-sm font-normal">
        <TiptapEditor editable={false} rich_text_body={body} />
      </div>
      {sources && (
        <div className="mt-4">
          <Sources sources={sources} />
        </div>
      )}
    </div>
  );
};
