import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { CommunitySegmentRule } from "@circle-react/types/CommunitySegment";

interface ListSegmentsParams {
  params: {
    page?: number;
    per_page?: number;
  };
}

const list = ({ params = {} }: ListSegmentsParams) =>
  reactQueryGet(internalApi.segments.index({ params }));

const createSegment = ({
  title,
  rules,
  visible,
  community_segment_consumer_attributes,
}: {
  title?: string;
  rules: CommunitySegmentRule;
  visible?: boolean;
  community_segment_consumer_attributes?: {
    consumer_id: string | number;
    consumer_type: string;
  };
}) =>
  reactQueryPost(internalApi.segments.index(), {
    title,
    rules,
    visible,
    community_segment_consumer_attributes,
  });

const deleteSegment = ({ id }: { id: number }) =>
  reactQueryDelete(internalApi.segments.detail({ id }));

const showSegment = ({ id }: { id: number }) =>
  reactQueryGet(internalApi.segments.detail({ id }));

const updateSegment = ({
  id,
  title,
  rules,
}: {
  id: number;
  title?: string;
  rules?: CommunitySegmentRule;
}) => reactQueryPut(internalApi.segments.detail({ id }), { title, rules });

const duplicateSegment = ({ id, title }: { id: number; title: string }) =>
  reactQueryPost(internalApi.segments.duplicate({ id }), { title });

const usageStatus = ({ id }: { id: number }) =>
  reactQueryGet(internalApi.segments.usageStatus({ id }));

export const segmentsApi = {
  list,
  delete: deleteSegment,
  create: createSegment,
  show: showSegment,
  update: updateSegment,
  duplicate: duplicateSegment,
  usageStatus,
} as const;
