import { t } from "@/i18n-js/instance";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { useThemeContext } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Form } from "@circle-react-shared/uikit/Form";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { RadioGroupBox } from "./RadioGroupBox";

interface FormDataProps
  extends Pick<AgentProp, "name" | "purpose" | "answer_type" | "tone"> {}

export const BehaviorPage = ({ agent }: { agent: AgentProp }) => {
  const { isDarkAppearance } = useThemeContext();

  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  if (!agent) return null;

  const formDefaultValues: FormDataProps = {
    name: agent.name,
    purpose: agent.purpose,
    answer_type: agent.answer_type,
    tone: agent.tone,
  };

  return (
    <div className="flex flex-col">
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-primary w-[453px] flex-col">
          <div className="border-primary border-b px-6 py-5">
            <Typography.LabelLg weight="semibold">
              {t("community_bot.bot_builder.behavior_page.title")}
            </Typography.LabelLg>
          </div>
          <div className="h-auto px-6">
            <div className="flex items-center">
              <Form.Label
                fieldId="purpose"
                label={t(
                  "community_bot.bot_builder.behavior_page.form.purpose.label",
                )}
                hideBorder
                labelClassName="cursor-text"
              />

              <TippyV2
                content={t(
                  "community_bot.bot_builder.behavior_page.form.purpose.tooltip_text",
                )}
              >
                <Icon
                  type="16-question-mark"
                  className="ml-1 mt-1"
                  size={16}
                  useWithStrokeCurrentColor
                />
              </TippyV2>
            </div>

            <Form.Input
              name="purpose"
              variant="textarea"
              defaultValue={formDefaultValues.purpose}
              rows={4}
              maxLength={500}
              placeholder={t(
                "community_bot.bot_builder.behavior_page.form.purpose.placeholder",
              )}
            />
            <Form.Label
              fieldId="answer_type"
              label={t(
                "community_bot.bot_builder.behavior_page.form.answer_type.label",
              )}
              hideBorder
              labelClassName="cursor-text"
            />
            <RadioGroupBox
              fullWidth
              name="answer_type"
              options={[
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.answer_type.ai_generated",
                  ),
                  value: "ai-generated",
                  rightIcon: (
                    <Icon
                      type="24-ai-list-sparkle"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                },
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.answer_type.custom",
                  ),
                  value: "custom",
                  disabled: true,
                  rightIcon: (
                    <Icon
                      type="24-note-text"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                },
              ]}
            />
            <Form.Label
              fieldId="tone"
              label={t(
                "community_bot.bot_builder.behavior_page.form.tone.label",
              )}
              hideBorder
              labelClassName="cursor-text"
            />
            <RadioGroupBox
              className="grid grid-cols-2 gap-4"
              name="tone"
              options={[
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.tone.normal_label",
                  ),
                  value: "normal",
                  rightIcon: (
                    <Icon
                      type="20-normal-behavior-type"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                  description: t(
                    "community_bot.bot_builder.behavior_page.form.tone.normal_description",
                  ),
                },
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.tone.friendly_label",
                  ),
                  value: "friendly",
                  rightIcon: (
                    <Icon
                      type="20-friendly-behavior-type"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                  description: t(
                    "community_bot.bot_builder.behavior_page.form.tone.friendly_description",
                  ),
                },
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.tone.short_and_sweet_label",
                  ),
                  value: "short-and-sweet",
                  rightIcon: (
                    <Icon
                      type="20-short-and-sweet-behavior-type"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                  description: t(
                    "community_bot.bot_builder.behavior_page.form.tone.short_and_sweet_description",
                  ),
                },
                {
                  label: t(
                    "community_bot.bot_builder.behavior_page.form.tone.descriptive_label",
                  ),
                  value: "descriptive",
                  rightIcon: (
                    <Icon
                      type="20-descriptive-behavior-type"
                      size={24}
                      useWithStrokeCurrentColor
                    />
                  ),
                  description: t(
                    "community_bot.bot_builder.behavior_page.form.tone.descriptive_description",
                  ),
                },
              ]}
            />
          </div>
        </div>
        <ChatPreview />
      </div>
    </div>
  );
};
