import { useLocation } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";

export const useShouldDisplayV3OptInBar = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { isSettingsRoute } = useSettingsHeader();
  const { pathname } = useLocation();
  const is2faPage = pathname.startsWith("/two_fa");

  const isV3Enabled = !!currentCommunitySettings?.circle_v3_enabled;
  const isV3InPreview = !!currentCommunitySettings?.circle_v3_preview;

  return !isV3Enabled && !isV3InPreview && !isSettingsRoute && !is2faPage;
};
