import type { Location } from "history";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { DynamicLayout } from "@circle-react/components/Layout/DynamicLayout";
import { LeaderboardPage } from "@circle-react/components/Leaderboard";
import { PostModalComponent } from "@circle-react/components/PostsV3/PostModal";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { CoursesPage } from "@circle-react/pages/Courses";
import { EventsPage } from "@circle-react/pages/Events";
import { HomePageV2 } from "@circle-react/pages/Home";
import { MembersPage } from "@circle-react/pages/Members";
import { PostPage } from "@circle-react/pages/Post";
import { ProfilePage } from "@circle-react/pages/Profile";
import { SpacePage } from "@circle-react/pages/Space";
import { SpaceGroupPage } from "@circle-react/pages/SpaceGroup";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

const spacePaths = [
  "/c/:spaceSlug",
  "/c/:spaceSlug/invite",
  "/c/:spaceSlug/members",
  "/c/:spaceSlug/customize",
  "/c/:spaceSlug/customize/*",
  "/c/:spaceSlug/sections/*",
  "/c/:spaceSlug/edit-lesson/*",
];

export const RootRoute = () => {
  const { isV3Enabled } = useIsV3();
  const location = useLocation<{
    prevLocation?: Location;
    shouldFocusOnCommentsSection?: boolean;
  }>();
  const { state } = location;
  const { prevLocation } = state ?? {};

  return (
    <Route path="/">
      <DynamicLayout disableRightSidebar>
        <Switch location={prevLocation || location}>
          <Route
            component={HomePageV2}
            path={isV3Enabled ? "/feed" : "/home"}
            exact
          />
          {isV3Enabled && <Redirect from="/home" to="/feed" />}
          {!isV3Enabled && <Redirect from="/feed" to="/home" />}
          <ProtectedRoute component={MembersPage} path="/members" exact />
          <ProtectedRoute component={EventsPage} path="/events" exact />
          <ProtectedRoute component={CoursesPage} path="/courses" exact />
          <ProtectedRoute
            component={LeaderboardPage}
            path="/leaderboard"
            exact
          />
          <ProtectedRoute component={ProfilePage} path="/u/:publicUid" />
          <ProtectedRoute
            component={SpaceGroupPage}
            path="/s/:spaceGroupSlug"
          />
          <ProtectedRoute component={SpacePage} path={spacePaths} exact />
          <ProtectedRoute component={PostPage} path="/c/:spaceSlug/:postSlug" />
        </Switch>
        {prevLocation && (
          <ProtectedRoute
            component={PostModalComponent}
            path="/c/:spaceSlug/:postSlug"
          />
        )}
      </DynamicLayout>
    </Route>
  );
};
