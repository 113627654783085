import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useThemeContext } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { AllFiltersList } from "@circle-react-shared/MemberFilters/DynamicFilters";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Form } from "@circle-react-uikit/Form";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { DynamicFilters } from "./DynamicFilters";
import { usePlatformMemberFilters } from "./usePlatformMemberFilters";
import "./styles.scss";

export const AudiencePage = ({
  agent,
  filters,
}: {
  agent: AgentProp;
  filters: Array<BaseFilter | ProfileFieldBaseFilter>;
}) => {
  const { isDarkAppearance } = useThemeContext();
  const [audienceCount, setAudienceCount] = useState(0);

  const { getValues } = useFormContext();
  const sendWelcomeMessage = getValues("send_welcome_message");
  const welcomeMessage = getValues("welcome_message");

  useEffect(() => {
    void reactQueryPost(internalApi.communityBot.agents.audienceCount(), {
      members_filters: filters || [],
    }).then(res => {
      setAudienceCount(res.total_count);
    });
  }, [filters]);

  const { finalFilters, isLoadingProfileFields, customProfileFields } =
    usePlatformMemberFilters();

  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  if (!agent || isLoadingProfileFields) return null;

  return (
    <div className="flex flex-col">
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex h-full"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-primary w-[453px] flex-col">
          <div className="border-primary border-b px-6 py-5">
            <Typography.LabelLg weight="semibold">
              {t("community_bot.bot_builder.audience_page.title")}
            </Typography.LabelLg>
          </div>
          <div className="h-auto px-6">
            <Form.Label
              fieldId="members_filters"
              label={t("community_bot.bot_builder.audience_page.filters_label")}
              hideBorder
              labelClassName="cursor-text"
            />
            <div className="bg-secondary border-primary border-collapse rounded-t-md border px-6 py-3">
              <Icon type="16-audience" size={16} className="mr-2" />
              <Typography.LabelSm weight="semibold" color="text-light">
                {t("community_bot.bot_builder.audience_page.audience_label")}
              </Typography.LabelSm>
              <span className="ml-1">
                <Typography.LabelSm weight="semibold" color="text-dark">
                  {audienceCount}
                </Typography.LabelSm>
              </span>
            </div>
            <div
              id="audience-filters"
              className="border-primary border-collapse rounded-b-md border-x border-b"
            >
              <DynamicFilters
                stateFilters={filters}
                platformFilters={finalFilters}
                profileFieldFilters={customProfileFields}
              >
                <DynamicFilters.Panel>
                  <AllFiltersList />
                </DynamicFilters.Panel>
              </DynamicFilters>
            </div>
          </div>
          <div className="my-2 flex w-full items-center justify-center">
            <Icon type="audience-dots" />
          </div>
          <div className="h-auto px-6">
            <div className="border-primary rounded border px-6 pb-3">
              <Form.Checkbox
                className="flex w-auto font-normal"
                tooltipClassName="mt-[2px]"
                name="send_welcome_message"
                description={t(
                  "community_bot.bot_builder.audience_page.welcome_message_tooltip",
                )}
                isDescriptionTooltip
                label={t(
                  "community_bot.bot_builder.audience_page.send_welcome_message",
                )}
              />

              {sendWelcomeMessage && (
                <TiptapEditor
                  className="mt-2"
                  editorClassName="mt-2 bg-secondary p-3 text-sm border-t-md rounded-t-md rounded-br-md"
                  disabled
                  editable={false}
                  value={welcomeMessage?.body}
                  showMenuBar={false}
                />
              )}
            </div>
          </div>
        </div>
        <ChatPreview />
      </div>
    </div>
  );
};
