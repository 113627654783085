/* eslint-disable eslint-comments/no-restricted-disable, max-lines, sonarjs/cognitive-complexity -- File is too complex to refactor right now. Should be handled later */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import classNames from "classnames";
import { debounce, isFunction } from "lodash";
import { useDropzone } from "react-dropzone";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { SendMessageConfirmationModal } from "@circle-react/components/Chat/sharedV2/SendMessageConfirmationModal";
import { SharePrivateMessageConfirmationModal } from "@circle-react/components/Chat/sharedV2/SharePrivateMessageConfirmationModal";
import { isMobileOs } from "@circle-react/helpers/browserHelpers";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { TextModifiersMenu } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";
import { DragIndicator } from "./AttachmentPreviews/DragIndicator";
import { getTiptapExtensions } from "./Extensions";
import { MenuBar } from "./MenuBar";
import { PreviewBlocks } from "./PreviewBlocks";
import { EMPTY_EDITOR_BLOCKS } from "./constants";
import { clipboardTextParser } from "./utilities/clipboardTextParser";
import {
  editorHasNoMentionOrEntityOrMessage,
  sanitizeContent,
  sanitizeInputJson,
} from "./utilities/sanitizeContent";
import {
  generateLocalSgidToObjectMap,
  getSgidValuesFromHTML,
} from "./utilities/sgidForEditorContent";
import { useEditorLocalState } from "./utilities/useEditorLocalState";
import "./styles.scss";

const TipTapEditorContext = createContext<any>(null);
TipTapEditorContext.displayName = "TipTapEditorContext";

export const useTipTapEditorContext = (): any =>
  useContext(TipTapEditorContext);

export const TiptapEditor = ({
  editorRef,
  autofocus = false,
  disabled = false,
  editable = true,
  isUpdate = false,
  onBlur,
  onChange,
  onDelete,
  onFocus,
  onSubmit,
  placeholder = t("message_placeholder"),
  rich_text_body = {},
  shouldRefetch,
  shouldScrollIntoView,
  showMenuBar = true,
  spaceId,
  submitOnEnter = true,
  submitOnModEnter = false,
  openInternalLinksInCurrentTab = false,
  maxLength,
  value,
  className,
  editorClassName,
  editedAt,
  portalTargetId,
  menuBarComponent: MenuBarComponent = MenuBar,
  menubarProps = {},
  isLiveStream = false,
  isChatSpace = false,
  type = "chat", //Type can be chat, comment, or workflow
  shouldShowTextModifiersMenu = true,
  chatProps = {
    isThread: false,
    isDirectMessaging: false,
    isChatAdminOrModerator: false,
    chatParticipantsCount: 0,
    disableGroupMentions: false,
    isTyping: false,
  },
  shouldUseFullHeight = false,
  shouldResetAfterSubmit = true,
  chatRoomAccessArr = [],
  messageBoxClassName,
  ...passThroughProps
}: any) => {
  const [shouldShowConfirmationModal, toggleConfirmationModal] = useBoolean();
  const [
    shouldShowPrivateMessageConfirmationModal,
    togglePrivateMessageConfirmationModal,
  ] = useBoolean();
  const [
    shouldShowPrivateMessageConfirmationOnSubmit,
    setShouldShowPrivateMessageConfirmationOnSubmit,
  ] = useState(false);

  const isWorkflowType = type === "workflow";

  const richTextBody =
    editable && isWorkflowType
      ? {
          body: value?.body || value,
          sgids_to_object_map: value?.localSgidToObjectMap || {},
        }
      : rich_text_body;

  const {
    body: initialValue,
    attachments = [],
    sgids_to_object_map: sgidToObjectMap = {},
  } = richTextBody || {};

  const { arePopupsVisible } = useInternalEditorState();

  const {
    isFocused,
    setIsFocused,
    localSgidToObjectMap,
    addToLocalSgidToObjectMap,
    setLocalSgidToObjectMap,
    messageAttachments,
    addMessageAttachments,
    removeMessageAttachments,
    setMessageAttachments,
  } = useEditorLocalState({
    initialValue: isUpdate || isWorkflowType ? sgidToObjectMap : {},
  });

  const localSgidRef = useRef(localSgidToObjectMap);
  localSgidRef.current = localSgidToObjectMap;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localAttachments, setLocalAttachments] = useState<any>(
    editable ? attachments : [],
  );
  const shouldShowEditedLabel = Boolean(editedAt);

  const debouncedOnChange = debounce(editor => {
    if (!isFunction(onChange)) return;
    const json = editor.getJSON();
    onChange({ body: json, localSgidToObjectMap: localSgidRef.current });
  }, 500);

  const sanitizedInitialValue = useMemo(() => {
    if (isWorkflowType) {
      return sanitizeInputJson(value?.body || value || initialValue);
    }

    return sanitizeInputJson(value || initialValue);
  }, [isWorkflowType, value, initialValue]);

  const editor = useEditor({
    editable,
    content: sanitizedInitialValue,
    onUpdate: ({ editor }) => {
      sanitizeContent({ editor });
      debouncedOnChange(editor);
    },
    onFocus: () => {
      setIsFocused(true);
      isFunction(onFocus) && onFocus();
    },
    onBlur: () => {
      setIsFocused(false);
      isFunction(onBlur) && onBlur();
    },
    autofocus,
    extensions: getTiptapExtensions({
      placeholder,
      openInternalLinksInCurrentTab,
      maxLength,
      portalTargetId,
      menubarProps,
      type,
    }),
    editorProps: {
      transformPastedHTML(html: any) {
        const sgids: string[] = getSgidValuesFromHTML(html);
        if (sgids.length) {
          void generateLocalSgidToObjectMap({
            sgids,
            setLocalSgidToObjectMap,
          });
        }
        return html;
      },
      clipboardTextParser,
      attributes: {
        class: classNames(editorClassName, "tiptap-editor"),
      },
    },
  });

  const isUploading = localAttachments.some((a: any) => a.progress >= 0);
  const editorHasNoText = () =>
    editor?.state?.doc?.textContent.trim().length === 0;

  const editorHasNoContent = () =>
    editorHasNoMentionOrEntityOrMessage(editor) &&
    (editor?.isEmpty || editorHasNoText());

  const resetEditor = () => {
    editor?.commands.clearContent();
    setLocalAttachments([]);
    setLocalSgidToObjectMap({});
    setMessageAttachments([]);
  };

  const handleSubmit = async (skipConfirmationModal = false): Promise<void> => {
    const isEditorEmpty = editorHasNoContent() && !localAttachments.length;
    if ((isEditorEmpty && !isUpdate) || isUploading) return;
    if (isEditorEmpty && isUpdate) {
      return onDelete?.();
    }
    const mentions = Object.values(localSgidToObjectMap);
    const hasGroupMentions = mentions.some(
      (mention: any) => mention.type == "group_mention",
    );
    if (!skipConfirmationModal && hasGroupMentions) {
      toggleConfirmationModal();
      return;
    }
    if (shouldShowPrivateMessageConfirmationOnSubmit) {
      togglePrivateMessageConfirmationModal();
      return;
    }
    if (isFunction(onSubmit)) {
      const submissionPromise = onSubmit({
        body: editorHasNoContent() ? EMPTY_EDITOR_BLOCKS : editor?.getJSON(),
        attachments: localAttachments.map(({ signed_id }: any) => signed_id),
        localAttachments,
        localSgidToObjectMap,
      });
      try {
        setIsSubmitting(true);
        await submissionPromise;
      } catch (e) {
        console.error(e);
      } finally {
        setIsSubmitting(false);
        if (shouldResetAfterSubmit) {
          resetEditor();
        }
      }
    }
  };

  const submitHotkeys = [
    ...(submitOnModEnter ? ["meta+enter, ctrl+enter"] : []),
    ...(submitOnEnter ? ["enter"] : []),
  ].join(", ");

  useHotkeys(
    submitHotkeys,
    () => {
      void handleSubmit();
    },
    {
      enabled:
        isFocused &&
        (submitOnEnter || submitOnModEnter) &&
        !arePopupsVisible &&
        !isMobileOs(),
      enableOnContentEditable: true,
    },
    [
      editor,
      localAttachments,
      arePopupsVisible,
      isFocused,
      shouldShowPrivateMessageConfirmationOnSubmit,
    ],
  );

  const addFilesToAttachments = (files: any) =>
    setLocalAttachments((prevAttachments: any) => [
      ...prevAttachments,
      ...files.map((file: any) => ({
        file,
        url: URL.createObjectURL(file),
        filename: file.name,
        byte_size: file.size,
        content_type: file.type,
        toUpload: true,
        progress: 0,
      })),
    ]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFilePicker,
  } = useDropzone({
    onDrop: addFilesToAttachments,
    noClick: true,
  });

  const fileInputProps: any = getInputProps();

  useEffect(() => {
    shouldRefetch &&
      editor &&
      editor.commands.setContent(sanitizedInitialValue); // Re rending editor after the initial value is changed on condition
    if (
      !editable &&
      shouldShowEditedLabel &&
      editor &&
      "addEditedLabelNode" in editor.commands &&
      typeof editor.commands.addEditedLabelNode === "function"
    ) {
      setTimeout(editor.commands.addEditedLabelNode, 0); // Wait for editor to set initial content
    }
  }, [
    sanitizedInitialValue,
    editor,
    shouldRefetch,
    shouldShowEditedLabel,
    editable,
  ]);

  if (!editor) {
    return null;
  }

  if (editorRef) {
    editorRef.current = editor;
  }

  const contextValue: any = {
    editor,
    sgidToObjectMap,
    localSgidToObjectMap,
    addToLocalSgidToObjectMap,
    attachments,
    localAttachments,
    setLocalAttachments,
    shouldScrollIntoView,
    shouldRefetch,
    spaceId,
    editedAt,
    portalTargetId,
    type,
    chatProps,
    isUpdate,
    messageAttachments,
    addMessageAttachments,
    removeMessageAttachments,
    chatRoomAccessArr,
    setShouldShowPrivateMessageConfirmationOnSubmit,
  };

  const charactersCount = editor?.storage?.characterCount?.characters() || 0;
  const countText = `${String(charactersCount)}/${String(maxLength)}`;
  const newMessageBoxStyles =
    "bg-tertiary focus-within:bg-primary hover:bg-primary active:bg-primary hover:border-dark border-hover rounded-xl border active:border-very-dark focus-within:border-very-dark hover:focus-within:border-very-dark";

  const { isTyping } = chatProps;
  return (
    <TipTapEditorContext.Provider value={contextValue}>
      {!editor.isEditable ? (
        <>
          {!editorHasNoContent() && <EditorContent editor={editor} />}
          {!isUpdate && <PreviewBlocks />}
        </>
      ) : (
        <div
          className={classNames(
            "text-dark relative flex flex-col px-4 py-3 text-sm transition-colors",
            isChatSpace ? "md:mb-6" : "mb-6",
            shouldUseFullHeight
              ? "bg-primary !mb-0 h-full"
              : newMessageBoxStyles,
            {
              "border-none": isDragActive,
              "border-light": !editorHasNoContent(),
              "pointer-events-none opacity-70": disabled || isSubmitting,
              "!mb-0": isTyping,
              "!bg-primary": isUpdate,
            },
            className,
          )}
          {...passThroughProps}
          {...getRootProps()}
          onPaste={e => {
            if (e.clipboardData?.files?.length > 0) {
              e.preventDefault();
              const files = Array.from(e.clipboardData.files);
              addFilesToAttachments(files);
            }
          }}
          data-testid="tiptap-editor"
        >
          {isDragActive && <DragIndicator />}
          <input {...fileInputProps} />
          <div
            className={classNames(
              "chat-message-word-wrap cursor-text overflow-auto",
              shouldUseFullHeight ? "h-full" : "max-h-[300px]",
              messageBoxClassName,
            )}
          >
            <EditorContent editor={editor} />
            {maxLength && <div className="text-gray-400">{countText}</div>}
          </div>
          {editor && shouldShowTextModifiersMenu && (
            <TextModifiersMenu editor={editor} />
          )}
          <PreviewBlocks />
          {showMenuBar && (
            <MenuBarComponent
              isSubmitting={isSubmitting}
              isUploading={isUploading}
              openFilePicker={openFilePicker}
              editor={editor}
              handleSubmit={handleSubmit}
              editorHasNoContent={() =>
                isUpdate
                  ? false
                  : editorHasNoContent() && !localAttachments.length
              }
              isFocused={isFocused}
              isUpdate={isUpdate}
              isLiveStream={isLiveStream}
              portalTargetId={portalTargetId}
              {...menubarProps}
            />
          )}
        </div>
      )}
      <SendMessageConfirmationModal
        shouldShowConfirmationModal={shouldShowConfirmationModal}
        toggleConfirmationModal={toggleConfirmationModal}
        handleSubmit={handleSubmit}
        chatParticipantsCount={chatProps.chatParticipantsCount}
      />
      {shouldShowPrivateMessageConfirmationModal && (
        <SharePrivateMessageConfirmationModal
          shouldShowPrivateMessageConfirmationModal={
            shouldShowPrivateMessageConfirmationModal
          }
          togglePrivateMessageConfirmationModal={
            togglePrivateMessageConfirmationModal
          }
          handleSubmit={handleSubmit}
          setShouldShowPrivateMessageConfirmationOnSubmit={
            setShouldShowPrivateMessageConfirmationOnSubmit
          }
          shouldShowPrivateMessageConfirmationOnSubmit={
            shouldShowPrivateMessageConfirmationOnSubmit
          }
        />
      )}
    </TipTapEditorContext.Provider>
  );
};
